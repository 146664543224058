import { ALL_PRODUCTS, get_all_products } from "../actions/feed";

const defaultState =  {
    open:false,
    products:[]
};

export const feed = (state = defaultState, action) => {

    const {type,payload} = action;
    switch (type) {
      case ALL_PRODUCTS:{
        state = get_all_products(state, payload)
        return state
      }
      default: return state
    }
  }