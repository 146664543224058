import { createStore,combineReducers } from "redux";
import { feed } from "./reducers/feed";
import { loading_screen } from "./reducers/loader";
import { profile } from "./reducers/profile";
import { shops } from "./reducers/shop";
import { chatLists } from "./reducers/messages";
import { alert_display } from "./reducers/alert";
import { post_modal } from "./reducers/modal";
// import { createStore,applyMiddleware, compose} from 'redux'



// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  as any || compose;

const reducers = {
  loading_screen,
  profile,
  feed,
  shops,
  chatLists,
  alert_display,
  post_modal
  
}
// const allReducers = combineReducers({
//     loading_screen,
//     profile,
//     feed,
//     shops,
//   });

  const rootReducer = combineReducers(reducers)

  export const configureStore = () => createStore(rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && 
    window.__REDUX_DEVTOOLS_EXTENSION__());