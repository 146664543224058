const Categories =  [
    { code:0, name:'Electronics', subCategories:[]},
    { code:1, name:'Fashion & Clothing',subCategories:[]},
    { code:2, name:'Business, Office & Industrial Supplies',subCategories:[]},
    { code:3, name:'Health & Beauty', subCategories:[]},
    { code:4, name:'Motors',subCategories:[]},
    { code:5, name:'Home Garden',subCategories:[]},
    { code:6, name:'Sports, Hobbies & Leisure',subCategories:[]},
    { code:7, name:'Collectables & Art',subCategories:[]},
    { code:8, name:'Media',subCategories:[]},
    { code:99, name:'Others',subCategories:[]},
]
export default Categories;