import React from 'react';

import { Link} from "react-router-dom"

const Navigation = ({location})=>{

    return(
        <nav>
            <ul className="profile_navbar">
                <li className="profile_nav_item"><Link to="/profile/myshop" className={location === '/profile/myshop'? "active":""}>MyShop</Link></li>
                <li className="profile_nav_item"><Link to="/profile/account" className={location === '/profile/account'? "active":""}>Account Details</Link></li>
                <li className="profile_nav_item"><Link to="/profile/changepassword" className={location === '/profile/changepassword'? "active":""}>Change Password</Link></li>
                <li className="profile_nav_item"><Link to="/profile/settings" className={location === '/profile/settings'? "active":""}>Settings</Link></li>
            </ul>
        </nav>
    )
}

export default Navigation;