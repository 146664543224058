
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation'




const navItems = [
  {
    name: "My Shop",
    action: "MYSHOPS"
  },
  {
    name: "Sells",
    action: "SELLS"
  },
  {
    name: "Watchlist",
    action: "WATCHLIST"

  },
  {
    name: "Favourites",
    action: "FAVOURITES"
  },
  {
    name: "Sold",
    action: "SOLD"
  },
  {
    name: "Expired",
    action: "EXPIRED"
    
    
  }

]

const NavSlider = (props:any) => {
  return (
    <Swiper 
    slidesPerView={'auto'}
    navigation={false}
    modules={[Navigation]}
    className="profile-body-nav">
        
        {navItems && navItems.map((item, index)=>{
        return <SwiperSlide key={index}>
                  <button
                    onClick={() => props.set_tab(`${item.action}`)}
                    className={
                      props.tab === item.action ? "profile-body-nav-active" : ""
                    }
                  >
                    {item.name}
                  </button>
              </SwiperSlide>
      })}
        
    </Swiper>
  );
}

export default NavSlider;