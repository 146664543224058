import React, { useState,useEffect, useCallback } from 'react';
import httpClient from '../../services/ApiCallHandler';
import * as SERVICE from "../../services";
import { useSelector, useDispatch} from "react-redux"
import { load } from "../../redux/actions/profile";
import { toggle_loader } from "../../redux/actions/general";

import { userDataObjectFormatter } from "../../services/Helpers";


const Account = () => {
  const profile = useSelector(state => state.profile)
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    firstName: profile.firstName,
    lastName: profile.lastName,
    message: profile.message,
    about: profile.about,
    profilePhoto: profile.profilePhoto,
    dateOfBirth: profile.dateOfBirth,
    location: profile.location,
    country: profile.country,
    city: profile.city,
    profession: profile.profession,
    title: profile.title,
    email:profile.email,
    phone:profile.phone
   
  });
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(toggle_loader(true));
    const postDataObject = {
      userId:profile.id,
      username: formData.username,
      firstName: formData.firstName,
      lastName:  formData.lastName,
      message:  formData.message,
      about:  formData.about,
      dateOfBirth:  formData.dateOfBirth,
      location: formData.location,
      country:  formData.country,
      city:  formData.city,
      profession:  formData.profession,
      title: formData.title
    }
    let config = SERVICE.updateUserProfile(postDataObject);


    httpClient(config)
      .then(function (response) {
        console.log(response.data.user)
        const loginData = userDataObjectFormatter(response.data.user);
        dispatch(load(loginData))
        dispatch(toggle_loader(false));

      })
      .catch(function (error) {
        console.log(error);
      })
  }
  const handleSubmitContact = (e) => {
    e.preventDefault();
    dispatch(toggle_loader(true));
    const postDataObject = {
      userId:profile.id,
      email:formData.email,
      phone:formData.phone
    }
    let config = SERVICE.updateUserProfile(postDataObject);
    console.log(postDataObject, "data")

    httpClient(config)
      .then(function (response) {
        console.log(response.data.user)
        const loginData = userDataObjectFormatter(response.data.user);
        dispatch(load(loginData))
        dispatch(toggle_loader(false));

      })
      .catch(function (error) {
        console.log(error);
      })
  }
  const setinitialData = useCallback(() => {

    setFormData(
      {
        firstName: profile.firstName,
        lastName: profile.lastName,
        message: profile.message,
        about: profile.about,
        profilePhoto: profile.profilePhoto,
        dateOfBirth: profile.dateOfBirth,
        location: profile.location,
        country: profile.country,
        city: profile.city,
        profession: profile.profession,
        title: profile.title,
        email:profile.email,
        phone:profile.phone
      
        
      }
    )
  },[profile.about, profile.city, profile.country, profile.dateOfBirth, profile.email, profile.firstName, profile.lastName, profile.location, profile.message, profile.phone, profile.profession, profile.profilePhoto, profile.title])
  
  useEffect(() => { 
    setinitialData() 
  }, [setinitialData])
  

  return (
    <>
      <div className="account">
        <div className="content_container">
          <div className="content_heading">
            <h2>Profile Details</h2>
          </div>
          <form className="details_form" onSubmit={(e) => handleSubmit(e)}>
            <div className="form-group">
              <div className="form-control">
                <label htmlFor='firstname'>FirstName</label>
                <input type="text" className='form-input' name="firstName" id='firstname' onChange={(e) => handleChange(e)} value={formData.firstName || ""}></input>
                {/* <FormInput type="text" name="firstName" value={formData.firstName || ""} funHandle={handleChange}> FirstName</FormInput> */}
              </div>
              <div className="form-control">
                <label htmlFor='lastname'>Lastname</label>
                <input className='form-input' type="text" name="lastName" id='lastname' onChange={(e) => handleChange(e)} value={formData.lastName || ""}></input>
              </div>
            </div>
            <div className="form-group">
              <div className="form-control">
                <label htmlFor='profession'>Profession</label>
                <input type="text" className='form-input' name="profession" id='profession' onChange={(e) => handleChange(e)} value={formData.profession || ""}></input>
              </div>
              <div className="form-control">
                <label htmlFor='location'>Location</label>
                <input type="text" className='form-input' name="location" id='location' onChange={(e) => handleChange(e)} value={formData.location || ""}></input>
              </div>
            </div>
            <div className="form-group">
              <div className="form-control">
                <label htmlFor='country'>Country</label>
                <input type="text" className='form-input' name="country" id='country' onChange={(e) => handleChange(e)} value={formData.country || ""}></input>
              </div>
              <div className="form-control">
                <label htmlFor='city'>City</label>
                <input type="text" className='form-input' name="city" id='city' onChange={(e) => handleChange(e)} value={formData.city || ""}></input>
              </div>
            </div>
            <div className="form-control">
              <label htmlFor='birthday'>Birthdate</label>
              <input type="date" className='form-input' name="dateOfBirth" id='birthdate' onChange={(e) => handleChange(e)} value={formData.dateOfBirth || ""}></input>
            </div>
            <div className="form-control">
              <label htmlFor='title'>Title</label>
              <input type="text" className='form-input' name="title" id='title' onChange={(e) => handleChange(e)} value={formData.title || ""}></input>
            </div>
            <div className="form-control">
              <label htmlFor='about'>About Me</label>
              <input type="text" className='form-input' name="about" id='about' onChange={(e) => handleChange(e)} value={formData.about || ""}></input>
            </div>
            <div className="module_form_buttons">
              <button type="submit" className='btn-primary'>Update Profile Info</button>
            </div>
          </form>
        </div>
        <div className="contact_details">
          <div className="content_container">
            <div className="content_heading">
              <h2>Contact Information</h2>
            </div>
            <form className='contact_information' onSubmit={(e) => handleSubmitContact(e)}>
              <div className="form-control">
                <label htmlFor='phone'>Phone</label>
                <input type="text" className='form-input' name="phone" id='phone' onChange={(e) => handleChange(e)} value={formData.phone || ""}></input>
              </div>
              <div className="form-control">
                <label htmlFor='email'>Email</label>
                <input type="text" className='form-input' name="email" id='email' onChange={(e) => handleChange(e)} value={formData.email || ""} disabled={!profile.isEmailVerified}></input>
                 <em  style={{color:"red",display: profile.isEmailVerified? "none" : "flex"}}>you can not edit your email address because its not verified , <a  style={{color:"blue"}}href={`/verify?email=${profile.email}`}> click here </a>  to verify</em>
              </div>
              <div className="module_form_buttons">
              <button type="submit" className='btn-primary'>Update Contact Info</button>
            </div>
            </form>

          </div>
        </div>
      </div>
    </>
  )
}

export default Account