import { SET_ALEART } from "../actions/general";


const alertState =  {
    display:false,
    message: [],
    type:""
    }

export const alert_display = (state = alertState, action) => {

    const {type,payload} = action;
    switch (type) {
        case SET_ALEART:{
        state = payload
        return {...state}
        }
        default: return state
    }
    }