
import React from 'react';
import{AiOutlineLoading} from 'react-icons/ai'
import { useSelector} from "react-redux"
export default function LoadingScreen() {
    const open = useSelector((state:any) => state.loading_screen).open
    return (
        <div className={open ? "loading_screen":"loading_screen_off"}>
            <div className="loader_icon">
              <AiOutlineLoading/>
            </div>
        </div>
    )
}
