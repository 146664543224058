const updateHeaderInterceptor = (axiosInstance) => {
    let accessToken = localStorage.getItem('accessToken')
    let refreshToken = localStorage.getItem('refreshToken')
    
    axiosInstance.interceptors.request.use((config) => {
       config.headers["accessToken"] = accessToken;
       config.headers["refreshToken"] = refreshToken;

       return config;
    },(error) => {
       
    });
};
export default updateHeaderInterceptor;