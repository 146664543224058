import { LOAD } from "../actions/profile"

const defaultState =  {
  logged_in:false,
  isEmailVerified:false,
  id:"",
  email:"",
  firstName: "",
  lastName: "",
  message: "",
  about: "",
  profilePhoto:"",
  dateOfBirth: "",
  location: "",
  country: "",
  city: "",
  profession: "",
  title: "",
  phone:"",
  profilePhotoSource: "",
  coverPhoto:"",
  dp:"null"
};

export const profile = (state = defaultState, action) => {
    switch (action.type) {
      case LOAD:{
        return action.payload
      }
      default: return state
    }
  }