import Logo from "../general/Logo";
import './Navigation.scss';
import React from 'react';

import {
  BiX,
} from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Session from "../../services/Session";
import { useSelector, useDispatch} from "react-redux";
import { useEffect, useState } from "react";
import { modal_open, toggle_loader } from "../../redux/actions/general";
import * as SERVICE from "../../services";
import { load } from "../../redux/actions/profile";
import { all_products } from "../../redux/actions/feed";
import { set_watchlist,set_favourites } from "../../redux/actions/shop";
import { get_all_products } from "../../services";
import { useRef } from "react";
import SearchBar from "./SearchBar";
import HeaderMenu from "./NavMenu";
import { useLocation } from 'react-router-dom';
import httpClient from '../../services/ApiCallHandler';
import { sortChats } from '../../services/Helpers';
import {MdClose,MdMenu,MdSearch } from "react-icons/md";
import NavList from "./NavList";
import TabBar from "./TabBar";
import { connect} from "react-redux";


const Navigation = (props:Props) => {
  const dispatch = useDispatch();
  const old_products = useSelector((state:any) => state.feed);
  const [products, set_products] = useState(old_products.products);
  const [filterProducts, setFilterProducts] = useState([]);
  const location = useLocation().pathname;
  const [chatlistApi, setChatListApi]:any = useState([]);
  const [messageCounter, setMessageCounter] = useState(0);
  const profile = useSelector((state:any) => state.profile)

  console.log(props.modalState)


  useEffect(() => {
    // let date = new Date().getTime();
    let id = '';
    if(profile && profile.id){
      id = profile.id
    }
    get_all_products(id).then(function (response:any) {
      let new_products = response["data"]["products"]['products'];
      let userWatchlist = response["data"]["products"]['userWatchlist'].length > 0 ? response["data"]["products"]['userWatchlist'][0] : [];
      let userFavourites = response['data']['products']['userFavourites'].length > 0 ? response['data']['products']['userFavourites'][0] : [];
        // .filter((element) => {
        //   let start = new Date(element["start"]).getTime();
        //   let end = new Date(element["end"]).getTime();
        //   return end > date && start < date;
        // })
        // .reverse();
      dispatch(all_products(new_products));
      dispatch(set_watchlist(userWatchlist));
      dispatch(set_favourites(userFavourites));
      set_products(new_products);
    });
  }, [dispatch, profile]);

  const logout = () => {
    dispatch(toggle_loader(true));
    let data = { userId: profile["id"] };
    let config = SERVICE.logout(data);

    httpClient(config)
      .then(function (response) {
        if (response["data"]["loggedOut"]) {
          let data = {
            logged_in: false,
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            dp: null,
          };
          dispatch(load(data));
          Session({}, "LOGOUT");
          navigate("/");
        }
        dispatch(toggle_loader(false));
      })
      .catch(function (error) {
        dispatch(toggle_loader(false));
        console.log(error);
      });
  };


  let navigate = useNavigate();

  const handleFilter = (event:any) => {
    set_search_value(event.target.value);
    const searchWord = event.target.value;
    const newFilter = products.filter((value: { name: string; title: string; }) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase()) ||  value.title.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilterProducts([]);
    } else {
      setFilterProducts(newFilter);
    }
  };
  


  const resultContainer = useRef<null | HTMLDivElement>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const handleKeyDown = (e: { key: any; }) => {
    const { key } = e;
    let nextIndexCount = 0;

    if (key === "ArrowDown") {
      nextIndexCount = (focusedIndex + 1) % filterProducts.length;
    }
    if (key === "ArrowUp") {
      nextIndexCount =
        (focusedIndex + filterProducts.length - 1) % filterProducts.length;
    }
    if (key === "Escape") {
      console.log("keydown");
    }
    if (key === "Enter") {
      console.log("keydown");
    }
    setFocusedIndex(nextIndexCount);
  };

  useEffect(() => {
    if (!resultContainer.current) return;

    resultContainer.current.scrollIntoView({
      block: "center",
    });
  }, [focusedIndex]);





  let profile_image =  require("../../assets/avatar.jpeg"); //profile.dp ||

  const [openSearch, setOpenSearch] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [search_value, set_search_value] = useState("");
   useEffect(() => {
    const config = SERVICE.fetchUserMessages(profile.id)
    if (profile.id) {
          httpClient(config).then(function (response:any) {
            return setChatListApi(sortChats(response.data.chats, profile.id));
          
        }).catch(function (error) {
            console.log(error)
        })  
      
    }

   }, [profile.id])
   useEffect(() => {
    setMessageCounter(chatlistApi.filter((x:any) => x.seen === false && x.incoming === true).length)
   }, [chatlistApi])


   
   
  return (
    <>
      <nav className="site-nav">
        <div className="container">
          <div className="site-nav-wrapper">
            <button onClick={() => {
              setOpenMenu(true)
              document.body.setAttribute('style',"height:100vh; overflow:hidden;")
              }} className="nav-toggler icon icon-md md">
              <MdMenu />
            </button>
            <Logo />
            <SearchBar
            handleKeyDown ={handleKeyDown}
            openSearch ={openSearch}
            setOpenSearch ={setOpenSearch}
            handleFilter ={handleFilter}
            MdClose ={MdClose}
            MdSearch = {MdSearch}
            filterProducts = {filterProducts}
            focusedIndex = {focusedIndex}
            resultContainer ={resultContainer}
            set_search_value={set_search_value}
            search_value={search_value}
            location={location}
            />


            <NavList
            profile={profile}
            profile_image={profile_image}
            messageCounter={messageCounter}
            setModalState={props.setModalState} 
            modalState={props.modalState}
            logout={logout}/>

            <button 
              onClick={() => {
              setOpenSearch(true)
              document.body.setAttribute('style',"height:100vh; overflow:hidden;")
              }} className="search-icon icon icon-md md">
              <MdSearch/>
            </button>
          
              {/* <div className="mobile_icons">
              {profile.logged_in? (  
                <Link to="/messages" className="icon chat_icon">
                  <MdChatBubbleOutline />
                  <span className="counter" style={{display:(messageCounter === 0)? 'none' : 'block'}}>{messageCounter}</span>
                </Link>
                
                ):(
                  <Link to="/login" className="icon user-icon">
                  <BiUser/>
                  </Link>
                )}
              </div> */}
              {/* {profile.logged_in ? (
              <div className="header_icons">
                <div className="notifications">
                <button className="icon notification_icon">
                  <MdNotificationsNone  className="close_menu"/>
                </button>
                <NotificationMenu />
                </div>
                <button className="icon wishlist_icon">
                  < MdFavoriteBorder/>
                </button>
                <Link to="/messages" className="icon chat_icon">
                  <MdChatBubbleOutline />
                  <span className="counter" style={{display:(messageCounter === 0)? 'none' : 'block'}}>{messageCounter}</span>
                </Link>
                <div className="user-profile">
                  <button className="icon user_img">
                    <div className="image-container">
                      <img  src={profile_image} alt="user" />
                    </div>
                  </button>
                  <ProfileMenu
                  profile_image ={profile_image}
                  Link = {Link}
                  profile ={profile}
                  logout={logout} />
                </div>
              </div>
            ) : (
              <div className="header_icons">
                <div className="header_buttons">
                <Link to="/login" className="btn-secondary">
                 Login
                </Link>
                <Link to="/signup" className="btn-primary">
                 Sign up
                </Link>
              </div>

              </div>
            )}          */}
          </div>
          <HeaderMenu
            BiX={BiX}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            logout={logout}
            profile={profile}
            Link={Link} />
        </div>
      </nav>
      <TabBar setModalState={props.setModalState} modalState={props.modalState} profile={profile} profile_image={profile_image}/>
    </>
  );
}


type Props = StateProps & DispatchProps

interface StateProps {
  modalState: boolean
}

interface DispatchProps {
  setModalState: (data:boolean) => any
}


const mapState = (state:any)=>({
  modalState: state.post_modal.open,
})

const mapDispatch = (dispatch:any) =>({
  setModalState: (data:boolean) => dispatch(modal_open(data))
})

// const connector = connect(mapStateToProps, mapDispatchToProps);

// export default connector(Navigation);

export default connect<StateProps, DispatchProps>(
  mapState,
  mapDispatch
)(Navigation)