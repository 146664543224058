import React from "react";
import Auth from "../components/auth/Auth"
import LoginForm from "../components/auth/LoginForm";

const Login = ()=>{
    return(
        <Auth Authform={LoginForm}/>
    )
}

export default Login;