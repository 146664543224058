import React from "react"
import './ProfileMenu.scss';
import { MdHelpOutline, MdLogout, MdSettings } from "react-icons/md";
const ProfileMenu = ({profile_image, Link, profile,logout}:any)=>{
    return(
        <div className="profile-options">
          {profile.logged_in? (
            <>
                 <div className="user-details ptr">
                  <div className="user-img">
                    <div className="image-container">
                      <img src={profile_image} alt="user" />
                    </div>
                  </div>
                  <Link to="/profile/myshop" className="user-name">
                    <h4 className="name">
                      {" "}
                      {profile.firstName} {profile.lastName}
                    </h4>
                    <h5>View profile</h5>
                  </Link>
                </div>
                <Link to="/profile/settings" className="user-settings ptr">
                  <span>
                    <MdSettings />
                  </span>
                  <span>Settings</span>
                </Link>
                <span className="user-help ptr">
                  <span>
                    <MdHelpOutline/>
                  </span>
                  <span>Privacy & Help</span>
                </span>
                <span onClick={logout} className="user-logout ptr">
                  <span>
                    <MdLogout />
                  </span>
                  <span>logout</span>
                </span>
            </>

          ):(
            <>
              <div className="nav-auth">
                <span>
                <Link to="/login" >
                Login
                </Link>
                </span>
                <span>
                <Link to="/signup">
                 Sign up
                </Link>
                </span>

              </div>
            </>

          )}

              </div>
    )
}

export default ProfileMenu;