import React from 'react';
import { useEffect } from "react";
import { get_shop_products } from "../../services";
import axios from "axios";
import { useState } from "react";
import { toggle_loader } from '../../redux/actions/general';
import { useDispatch, useSelector} from "react-redux"
import { set_past_products } from "../../redux/actions/shop";
import Product from "../promo_cards/Product";

export default function PastProducts({shop_id}) {
    const dispatch = useDispatch()
    const past_products = useSelector(state => state.shops).past_products
    const [products, set_products] = useState(past_products)

    useEffect(()=>{
        let date = (new Date()).getTime()
        if(products.length < 1) dispatch(toggle_loader(true))
        let config = get_shop_products(shop_id)
        axios(config).then(function (response) {
            let new_products = response['data']['products'].filter(element => {
                let end = (new Date(element['end'])).getTime()
                return end < date
            })
            set_products(new_products)
            dispatch(toggle_loader(false))
            dispatch(set_past_products(new_products))
        })
        .catch(function (error) {
            console.log(error);
            dispatch(toggle_loader(false))
        });
    },[shop_id, dispatch, products.length])

    const allowed_actions = ["Restart", "Edit", "Delete", "Add to Watchlist"]

    return (
        <div className="shop_products">
            {products.map((product, index) => {
                return <Product product={product} allowed_actions={allowed_actions} key={index} />
            })}
        </div>
    )
}
