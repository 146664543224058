import React from 'react';
import Styles from './Shop.module.scss';
import EditShop from "../components/shop/EditShop";
import Footer from "../components/general/Footer";
import Navigation from "../components/navigation/Navigation";
import { FiEdit3 } from 'react-icons/fi';
import ActiveProducts from "../components/shop/ActiveProducts";
import PastProducts from "../components/shop/PastProducts";
import { useEffect, useState } from "react";
import Post from "../components/shop/Post";
import { get_shop } from "../services";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import AllPromos from "../components/shop/AllPromos";
import Session from "../services/Session";
import EditPost from "../components/shop/EditPost";
import httpClient from '../services/ApiCallHandler';
import * as SERVICE from '../services';
import { userDataObjectFormatter } from '../services/Helpers';
import { toggle_loader } from '../redux/actions/general';
import { load } from '../redux/actions/profile';

export default function Shop() {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const profile = useSelector((state:any) => state.profile);


    let { id, page } = useParams();
    const [view_shop, set_view_shop] = useState({
        name: "",
        shop_picture: require("../assets/store.jpeg")
    })

    const view_on_map = () => {
        window.open(`http://maps.google.com/?q=${view_shop.name}`)
    }

    

    const uploadImage = (image:any, type:String) => {
        dispatch(toggle_loader(true))
        interface Obj {
            file: any | null;
            src: string | ArrayBuffer | null;
        }
        const obj:Obj = { file: image, src: "" };
        const reader = new FileReader();
        reader.onloadend = () => {
            obj['src'] = reader.result
        }
        reader.readAsDataURL(image);
        console.log(image)

        let formData = new FormData()
        formData.append("image", image)
        formData.append("userId", profile.id)
        formData.append("profileSource", "internal")
        const config = SERVICE.updateUserProfilePhoto(formData, type)

        httpClient(config).then(function (response) {

            console.log(response)
            dispatch(load(userDataObjectFormatter(response["data"])))
            dispatch(toggle_loader(false))
        })
            .catch(function (error) {
                dispatch(toggle_loader(false))
                console.log(error);
            });
    }
    useEffect(() => {
        let { refreshToken, accessToken }:any = Session({}, 'GET')
        if (!refreshToken || !accessToken) navigate('/');
        dispatch(toggle_loader(true))
        let config = get_shop(id)
        axios(config).then(function (response) {
            let shop = response['data']['shop']
            // console.log(shop)
            shop['shop_picture'] = shop['dp'] || require("../assets/store.jpeg")
            shop['location'] = `${shop['name']} ${shop['address']} ${shop['city']} ${shop['state']}`
            set_view_shop(shop)
            dispatch(toggle_loader(false))
        })
            .catch(function (error) {
                dispatch(toggle_loader(false))
                console.log(error);
            });
    }, [dispatch, id, navigate, profile])

    return (
        <>
            <Navigation />
            <div className={Styles.shop_page}>
                <div className="profile_wrapper">
                <div className="profile_information">
                <div className={Styles.shop_header}>
                        <div className={Styles.shop_header_container}>
                            <div className="profile_background_image">
                                <div className="image-container">
                                    <img src={profile.coverPhoto} alt="cover" />
                                </div>
                                <label className="profile_edit_icon"  htmlFor="coverPic"><FiEdit3 /><input type="file" id="coverPic" name="coverPic"accept="image/*" hidden onChange={(e:any) => uploadImage(e.target.files[0],"cover")}/></label>
                            </div>

                            <div className="profile_top_details">
                                <div className="profile_picture_wrapper">
                                    <div className="image-container">
                                    <img src={view_shop.shop_picture} alt="pp" />
                                    </div>
                                    <label htmlFor="avatar" className="profile_edit_image_icon"><FiEdit3 /><input type="file" id="avatar" name="avatar"accept="image/*" hidden onChange={(e:any) => uploadImage(e.target.files[0],"profile")}/></label>
                                </div>
                                <div className="profile_name_wrapper">
                                    <p className="profile_name" onClick={view_on_map}>{view_shop.name}</p>
                                    {/* <p className="profile_email" onClick={view_on_map}>{view_shop.address}, {view_shop.city}</p> */}
                                    <Link to={"/Profile/myshop"}><span className="link_back">Back to Profile</span></Link>
                                </div>
                                <div className="profile_buttons">
                                    <button onClick={() => navigate(`/Shop/${id}/edit/0`)} className="pf_btn-secondary">
                                        Edit Shop
                                    </button>
                                    <button onClick={() => navigate(`/Shop/${id}/post/0`)} className="pf_btn-primary">
                                        Create Post
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className={Styles.shop_body}>
                                <div className="profile_body_nav">
                                    <button onClick={() => navigate(`/Shop/${id}/all/0`)} className={page === 'all' ? 'profile_body_nav_active' : ''}>All</button>
                                    <button onClick={() => navigate(`/Shop/${id}/active/0`)} className={page === 'active' ? 'profile_body_nav_active' : ''}>Active</button>
                                    <button onClick={() => navigate(`/Shop/${id}/past/0`)} className={page === 'past' ? 'profile_body_nav_active' : ''}>Past</button>
                                </div>
                                {
                                    page === 'update' ? <EditPost shop_id={id} /> :
                                        page === 'post' ? <Post shop_id={id} /> :
                                            page === 'past' ? <PastProducts shop_id={id} /> :
                                                page === 'edit' ? <EditShop shop_id={id} /> :
                                                    page === 'active' ? <ActiveProducts shop_id={id} /> :
                                                        <AllPromos shop_id={id} />
                                }
                      </div>
                    </div>
            </div>
            </div>
            
            <Footer />
        </>
    )
}