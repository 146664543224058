import React from 'react';
import Footer from "../components/general/Footer";
import Navigation from "../components/navigation/Navigation";
import '../css/Home.scss';
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { get_all_products } from "../services";
import Category from '../components/homepage/Category';
import ProductsSlider from '../components/homepage/ProductsSlider';
import { MdArrowForward } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay} from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-fade";
import CategoryCircles from '../components/homepage/CategoryCircles';
import { all_products } from '../redux/actions/feed';
import { set_favourites, set_watchlist } from '../redux/actions/shop';
// Import Swiper styles


 const slideProducts = [
    {
        id: 0,
        img: "https://images.unsplash.com/photo-1627384113890-8f8893f2411a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1200&q=80",
        description: " Style Get 50% Off Sitewide",
        color: "#ffff"
    },
    {
        id: 1,
        img: "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1200&q=80",
        description: "Style Get 50% Off Sitewide",
        color: "#ffff"
    },
    {
        id: 2,
        img: "https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1200&q=80",
        description: " Style Get 50% Off Sitewide",
        color: "black"
    },
    {
        id: 3,
        img: "https://images.unsplash.com/photo-1526170375885-4d8ecf77b99f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1200&q=80",
        description: "Style Get 50% Off Sitewide",
        color: "#ffff"
    }

 ]



const  HeroSlider = () => {
    return (
      <Swiper
      
        spaceBetween={0}
        speed={500}
        autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        loop={true}
        modules={[Autoplay]}
      >
        {slideProducts && slideProducts.map((product, index)=>{
          return <SwiperSlide className="hero_slider" key={index}>
                  <div className="image-container">
                      <img src={product.img} alt={product.description}></img>
                      <div className="container">
                              <div className="hero_content">
                              <h1>{product.description}</h1>
                              <button className="btn-secondary">Start Shopping <MdArrowForward/></button>
                          </div>
                      </div>
                  </div>
                 </SwiperSlide>
        })}
      </Swiper>
    );
  };




const Home = ()=>{


    const dispatch = useDispatch()
    const old_products = useSelector((state:any) => state.feed)
    const profile = useSelector((state:any) => state.profile)

    const [products, set_products] = useState(old_products.products)
    const allowed_actions = ["Add to Watchlist", "View Product", "Visit Store"]


    useEffect(()=>{
        //let date = (new Date()).getTime()
        let id = '';
        if(profile && profile.id){
          id = profile.id
        }
        get_all_products(id).then(function (response:any) {
            let new_products = response['data']['products']['products']
            let userWatchlist = response["data"]["products"]['userWatchlist'].length > 0 ? response["data"]["products"]['userWatchlist'][0] : [];
            let userFavourites = response['data']['products']['userFavourites'].length > 0 ? response['data']['products']['userFavourites'][0] : [];
            // .filter(element => {
            //     let start = (new Date(element['start'])).getTime()
            //     let end = (new Date(element['end'])).getTime()
            //     return end > date && start < date
            // }).reverse()
            dispatch(all_products(new_products))
            dispatch(set_watchlist(userWatchlist));
            dispatch(set_favourites(userFavourites));
            set_products(new_products)
        })
    },[dispatch, profile])

    return(
        <>
        <Navigation/>
        <Category/>
        <div className="hero">
            <HeroSlider/>
        </div>
        <section>
            <div className="container">
                <div className="section_heading">
                <h3>Explore Popular Categories</h3>
                <div className='see-all-tag'><span className='text'>See all </span><BsArrowRight/></div>

                </div>
                <div className="brands_wrapper"> 
                <div className="category_circles">
                        <CategoryCircles/>
                 </div>
                </div>     
            </div>
        </section>
        <section>
            <div className="container">
                <div className="section_heading">
                    <h3>Top deals</h3>
                    <div className='see-all-tag'><span className='text'>See all </span><BsArrowRight/></div>
                </div>
                <div className="products_slider">
                    <ProductsSlider products={products} allowed_actions={allowed_actions}/>
                </div>
            </div>
        </section>
        <section className="banner">
            <div className="container">
                <div className="banner_content">
                    <h2>Sneakers Never Run Out Of Style Get 50% Off Sitewide</h2>
                    <button className="btn-secondary">Start Shopping  <MdArrowForward/></button>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}

export default Home;