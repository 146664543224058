import React from 'react'
import { useSelector } from 'react-redux';


function AlertBox() {
    const data = useSelector((state : any) => state.alert_display) 

    const alertTypes = [
        "success",
        "error",
        "unauthorised",
        "warning"
    ];
    const alert_type = (alertTypes.includes(data.type))? data.type : 'error';
    return (
        <div className={data.display? "show" : "hide"}>
            <div className={alert_type}>
              {data.message.map((line:string,index:any) => {return <p key={index}>{line}</p> })}         
            </div>
        </div>
    )
}

export default AlertBox