import React from 'react';
import { useState } from 'react';


const ForgotPassword =()=>{

    const [email, set_email] = useState('')

    const [nextpage, setNextpage] = useState(true)

    return(
        <div className="form_page">
            <div className="form_container">
                {
                    nextpage?
                    <form>
                    <div className="form_header">
                    <h2>Forgot Password?</h2>
                        <p>You'll receive an email to confrim your identity</p>
                    </div>
                    <div className="form-control
">
                    <label htmlFor="email">Email</label>
                    <input className="form_input" type="text" name="email" placeholder="Enter your email" value={email} onChange={(e) => set_email(e.target.value)} />
                    </div>
                    <input type="button" className="btn-primary" value="Reset Password" onClick={()=>setNextpage(!nextpage)}  />
                    </form>:
                    <div>
                    <h1>Check your email</h1>
                    </div>

                }

            </div>
        </div>
    )
}

export default ForgotPassword;