import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import './ProductsSlider.scss';
import {productCardFilters} from "../../services/Helpers"

function ProductsSlider ({products, allowed_actions}:any){

    return(
        <Swiper
        slidesPerView={1.5}
        spaceBetween={15}
        navigation={true}
        breakpoints={{

          640: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
        modules={[Navigation]}
        className="products_swiper">
          
                {products &&
                        products.slice(0,10).map((product:any,index:any)=>{             
                          return(
                          <SwiperSlide key={index}>
                            {productCardFilters(product.type,product, allowed_actions, index)} 
                          </SwiperSlide>

                          )                       
                        })
                    }
        </Swiper>
    );
 }

 export default ProductsSlider;