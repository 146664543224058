import React,{ useState} from 'react';
import { useSelector, useDispatch} from "react-redux"
import { toggle_loader,display_alert } from "../../redux/actions/general";
import * as SERVICE from "../../services";
import httpClient from '../../services/ApiCallHandler';

const ChangePassword = ()=>{
  const profile = useSelector(state => state.profile)
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword:"",
    confirmPassword:""
   
  });
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  }

  const cancelChange = () => {
   return setFormData({
      oldPassword: "",
      newPassword:"",
      confirmPassword:""
     
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const  { oldPassword, confirmPassword, newPassword} = formData;
    console.log(confirmPassword , newPassword)
    if(confirmPassword ===  newPassword ){
      dispatch(toggle_loader(true));
      const postDataObject = {
        userId:profile.id,
        email:profile.email,
        oldPassword:oldPassword,
        newPassword:newPassword
      }
      let config = SERVICE.updateUserPassword(postDataObject);
      console.log(postDataObject, "data")
  
      httpClient(config)
        .then(function (response) {
          dispatch(toggle_loader(false));
          if(response.status === 200){
            dispatch(display_alert({display:true,
              message: ["password changed"],
              type:"success"}))
          } else {
            dispatch(display_alert({display:true,
              message: [response.data.message],
              type:"warning"}))
          }
  
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      dispatch(display_alert({display:true,
        message: ["passwprds dont match"],
        type:"warning"}))
        
    }
  
   
  }
    return(
      <div className='profile_security'>
        <div className="content_container">
        <div className="content_heading">
          <h2>Change your Password</h2>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="security_form_wrap">
            <div className="security_form">
              <div className="form-control">
                <label htmlFor='oldpassword'>Old Password</label>
                <input type='password' className='form-input' name='oldPassword' id='oldpassword' onChange={(e) => handleChange(e)}  value={formData.oldPassword} ></input>
              </div>
              <div className="form-control">
                <label htmlFor='newpassword'>New Password</label>
                <input type='password' className='form-input' name='newPassword' id='newpassword' onChange={(e) => handleChange(e)}  value={formData.newPassword}></input>
              </div>
              <div className="form-control">
                <label htmlFor='confirmpassword'>Confirm New Password</label>
                <input type='password' className='form-input' name='confirmPassword' id='confirmpassword' onChange={(e) => handleChange(e)}  value={formData.confirmPassword}></input>
              </div>
            </div>
            <div className="form_errors">
              <p>Password Must Contain</p>
              <span>At least 1 upper case letter (A - Z)</span>
              <span>At least 1 number (0 - 9)</span>
            </div>
          </div>
          <div className="module_form_buttons">
              <button className='btn-secondary' onClick={() => cancelChange()}>Cancel</button>
              <button type="submit" className='btn-primary'>Save</button>
            </div>
        </form>
        </div>
      </div>
    )
  }

  export default ChangePassword;