import React from "react";
import {FormInput} from "../general/FormElements";

const LoginForm = ({FcGoogle, formValidate, loginWithSocial,login_email,set_login_email, login_pass, set_login_pass, Link }:any) =>{
    return(
        <form className="login_form" onSubmit={(e)=>formValidate(e)} id="login">
        <div className="form_header">
          <h1>Sign into Mabine</h1>
          <p>See your growth and get consulting support!</p>
        </div>
        <span
          className="form_input google_auth ptr"
          onClick={(e) => {loginWithSocial(e,"google")}}
        >
          <span className="auth_icon">
            <FcGoogle />
          </span>
          <span>Sign in with Google</span>
        </span>
        <hr className="form_hr" />
       
        <div className="form-control">
          <FormInput value={login_email} funCall={set_login_email} name="email" type="email">
            Email
          </FormInput>
        </div>
        <div className="form-control">
          <label htmlFor="password"><div className="d-flex"><span>Password</span><Link to="/verify" className="blue ptr">Forgot Password?</Link></div></label>
          <input className="form-input" type="password" name="password" value={login_pass}  onChange={(e) => set_login_pass(e.target.value)} />
        </div>
        <div className="form-opt-wrap">
          <span>
            <input type="checkbox" name="remember" /> Remember Me
          </span>
        </div>
        <input
          type="submit"
          className="btn-primary"
          value="Sign In"
        />
         <div className="pad_nav">
         <p className="form-alt">
              Not Registered?
              <Link to="/signup"
                className="blue ptr" >
                {" "}
                Create an Account
              </Link>
            </p>
         </div>

      </form>
    )
}

export default LoginForm;