import React from 'react';
import  './Product.scss';
import { useEffect, useState } from "react";
import { Formatter} from "../../services/Helpers"; //Formatter, MoneyFormatter
import { BiDotsVerticalRounded } from "react-icons/bi"
import { Link } from "react-router-dom";
import { GrClose } from "react-icons/gr"
import { toggle_loader } from "../../redux/actions/general";
import { useDispatch, useSelector } from "react-redux";
import { ProductActions } from '../../services/Helpers';
import { MdFavoriteBorder } from 'react-icons/md';

export default function Product({ product, allowed_actions }) {
    const dispatch = useDispatch();
    const { view_product, action_btn, addDefaultSrc, action_btns } = new ProductActions()

    const profile = useSelector((state) => state.profile);
    const [hidden, setHidden] = useState(-1);
    

    const { _id, name, title, images, newPrice, end, start, storeId, userId, oldPrice } = product
    let right_now = (new Date()).getTime()
    let start_date = (new Date(start)).getTime()
    let end_date = new Date(end)
    let ends_seconds = Math.ceil((end_date.getTime() - right_now) / 1000)
    const [end_time, set_end_time] = useState(ends_seconds)
    const [open_actions, set_open_actions] = useState(false)
    if (allowed_actions && profile && profile.id === userId) {
        allowed_actions.push("Edit", "Delete")
    }
    useEffect(() => {
        let timer = setInterval(() => {
            set_end_time(ends_seconds - 60)
        }, 60000)
        return () => clearInterval(timer)
    }, [ends_seconds])



    return (
        <div className="product" onMouseEnter={()=> setHidden(_id) } onMouseLeave={()=> setHidden(-1)}>
        <figure className="product_image">
        <div className="product_top">
                <button className={`${hidden === _id? "product_like_show" :"product_like_hidden"}`}>
                    <MdFavoriteBorder/>
                </button>
                {/* <div className="product-type">Promotion</div> */}
            </div>
                <Link to={`/Product/${_id}`} className="product_image_cover">
                    <div className="image-container">
                    <img onClick={view_product} crossOrigin="anonymous" onError={(e) => addDefaultSrc(e)} src={images.length > 0 ? images[0]['Location'] : ""}  alt={name} />
                    </div>
                </Link>
        </figure>
        <div className="product_details">
                <div className="details">
                    <p className="product_ends">
                    <Formatter time={end_time}
                    right_now={right_now}
                    start_date={start_date}
                    end_date={end_date} /></p>
                    <p className="product_name">{name}</p>
                    <p className="product_desc">{title}</p>
                    <div className="product-price">
                        <p className="product_old"> K{oldPrice} </p>
                        <p className="product_discount price">K{newPrice}.00</p>
                        {/* <p className="product_percentage">{percentage}% off</p> */}
                    </div>
                </div>
                <div className="options">
                    <button className={ hidden === _id || open_actions? "options_show" :"options_hide"} onClick={() => set_open_actions(!open_actions)}>
                        {!open_actions ? <BiDotsVerticalRounded /> : <GrClose/>}
                    </button>
                    <div className="action_container">
                        <div className="action_row">
                            {allowed_actions && action_btns.map((element, index) => {
                                if (allowed_actions.includes(element) && open_actions) {
                                    return <button
                                        onClick={() =>  action_btn(element, storeId, _id, () => dispatch(toggle_loader(true)))}
                                        key={index}
                                        className="action_rows_btn">{element}
                                    </button>
                                }
                                else return ""
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="col_date">
                <p></p>
            </div>
            <div className="col_location">
                {location} Location
            </div>
            <div className="col_price">
                <p className="product_price">{newPrice} {currency}</p>
            </div>
            <div className="action_container">
                <button className="action_container_btn" onClick={() => set_open_actions(!open_actions)}>
                    {!open_actions ? <BiDotsVerticalRounded /> : <GrClose />}
                </button>
                <div className="action_row">
                    {allowed_actions && action_btns.map((element, index) => {
                        if (allowed_actions.includes(element) && open_actions) {
                            return <button
                                onClick={() => action_btn(element, storeId, _id, () => dispatch(toggle_loader(true)))}
                                key={index}
                                className="action_rows_btn">{element}
                            </button>
                        }
                        else return ""
                    })}
                </div>
            </div> */}
        </div>
    )
}
