 import React from "react";

 const Promotion = ({product}:any) => {
    return (
      <div className="promotions">
        <p>Other Products on Promotion by {product.shopName}</p>
        <div className="other_products">
          <div className="other_product_image">
            <img
              src="https://images.unsplash.com/photo-1661961110144-12ac85918e40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              alt="books"
            />
          </div>
          <div className="other_product_desc">
            <h4>Huawei Matebook D 15 </h4>
            <p>Intel Core i5 10210U 8GB Ram Model ...</p>
          </div>
          <div className="other_product_price">
            <div className="price_discount">5%</div>
            <div className="price_tag">
              <div className="prev_price">8,199.00 TL</div>
              <div className="price">7,299.00 TL</div>
            </div>
          </div>
        </div>
        <div className="other_products">
          <div className="other_product_image">
            <img
              src="https://images.unsplash.com/photo-1661961110144-12ac85918e40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              alt="foog"
            />
          </div>
          <div className="other_product_desc">
            <h4>Huawei Matebook D 15 </h4>
            <p>Intel Core i5 10210U 8GB Ram Model ...</p>
          </div>
          <div className="other_product_price">
            <div className="price_discount">5%</div>
            <div className="price_tag">
              <div className="prev_price">8,199.00 TL</div>
              <div className="price">7,299.00 TL</div>
            </div>
          </div>
        </div>
        <div className="other_products">
          <div className="other_product_image">
            <img
              src="https://images.unsplash.com/photo-1661961110144-12ac85918e40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              alt="computer"
            />
          </div>
          <div className="other_product_desc">
            <h4>Huawei Matebook D 15 </h4>
            <p>Intel Core i5 10210U 8GB Ram Model ...</p>
          </div>
          <div className="other_product_price">
            <div className="price_discount">5%</div>
            <div className="price_tag">
              <div className="prev_price">8,199.00 TL</div>
              <div className="price">7,299.00 TL</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

 export default Promotion;