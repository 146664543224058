import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import styles from './CategoryCircles.module.scss';



const categories = [
    {
        id: 0,
        image:{
            src: 'https://images.unsplash.com/photo-1479064555552-3ef4979f8908?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            alt: 'Fashion and Clothing'
        },
        name: 'Fashion and Clothing'

    },
    {
         id: 1,
        image:{
            src: 'https://images.unsplash.com/photo-1571781926291-c477ebfd024b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80',
            alt: 'Health and Beauty'
        },
        name: 'Health and Beauty',

    },
    {
        id: 2,
       image:{
           src: 'https://images.unsplash.com/photo-1617005082133-548c4dd27f35?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80',
           alt: 'Electronics'
       },
       name: 'Electronics',

   },
   {
    id: 3,
   image:{
       src: 'https://images.unsplash.com/photo-1513364776144-60967b0f800f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
       alt: 'Collectables and Art'
   },
   name: 'Collectables and Art',
 
   },
   {
    id: 4,
   image:{
       src: 'https://images.unsplash.com/photo-1617103996702-96ff29b1c467?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
       alt: 'Home Decor'
   },
   name: 'Home Decor',
 
   },
   {
    id: 5,
   image:{
       src: 'https://images.unsplash.com/photo-1486262715619-67b85e0b08d3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
       alt: 'Motor'
   },
   name: 'Motor',
 
   },
   {
    id: 6,
   image:{
       src: 'https://images.unsplash.com/photo-1628621317388-6ebde0c18574?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
       alt: 'Business, Office & Industrial Supplies'
   },
   name: 'Office Supplies',
 
   }
    
]

function CategoryCircles(){
    return(
        <Swiper
        slidesPerView={3}
        spaceBetween={5}
        breakpoints={{

          640: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 7,
            spaceBetween: 5,
          },
        }}
        modules={[Navigation]}
        className="category_circles_swiper">
             {categories && categories.map((circle, index)=> {
                return  <SwiperSlide key={index}>
                     <div className={styles.category_circle} >
                        <div className={styles.category_image}>
                            <div className="image-container">
                                <img src={circle.image.src} alt={circle.image.alt} />
                            </div>
                        </div>
                        <div className={styles.category_text}>{circle.name}</div>
                      </div>  
                </SwiperSlide>

            })}

        </Swiper>
    )
}
    

// const CategoryCircles = () =>{
//     return(
//         <>
//             {categories && categories.map((circle, index)=> {
//                 return <div className={styles.category_circle} key={index}>
//                         <div className={styles.category_image}>
//                             <div className="image-container">
//                                 <img src={circle.image.src} alt={circle.image.alt} />
//                             </div>
//                         </div>
//                         <div className={styles.category_text}>{circle.name}</div>
//                     </div>  
//             })}
//         </>

//     )
// }

export default CategoryCircles