import React from 'react';
import './Settings.scss';


const SettingsTabContent = (props:any) =>{
    return(
        <div className="settings-tab-content">
        <div className="content-header">
           <h4>{props.heading}</h4>
        </div>
        <div className="content-wrapper">
            <div className="text">
                <p>{props.paragraph}</p>
            </div>
            <div className="toggler">
                <div className="toggler-wrapper">
                    <label className="toggler">
                    <input type="checkbox"></input>
                    <span className="toggler-slider round"></span>
                    </label>
                </div>     
            </div>
        </div>
    </div>

    )
}

const Settings = ()=>{
   return(
    <>
    <div className="content_container">
        <div className="content_heading">
            <h2>Notifications</h2>
            <p>You can specify the methods you prefer to be informed about important campaigns.</p>
        </div>
        <SettingsTabContent 
        heading="SMS" 
        paragraph="Messages that will be sent to your mobile phone by Mabine via SMS method"/>

        <SettingsTabContent
        heading="Email"
        paragraph="Messages to be sent to your e-mail address registered in your Mabine account"
        />
        <SettingsTabContent
        heading="Call"
        paragraph="ncoming calls from Mabine to your mobile phone"
        />
    </div>
    </>

   )
}


export default Settings