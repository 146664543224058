import { TOGGLE_LOADER } from "../actions/general";


const defaultState =  {
    open:false
};

export const loading_screen = (state = defaultState, action) => {

    const{type,payload} = action;
    switch (type) {
      case TOGGLE_LOADER:{
        state.open = payload
        return {...state}
      }
      default: return state
    }
  }