import React from 'react';
import './Footer.scss'

const Footer = () =>{
    return(
         <footer>
            <div className="container">
                <div className="footer-links">
                    <ul className="footer-list">
                        <li className='footer-list-item'>Blog</li>
                        <li className='footer-list-item'>About</li>
                        <li className='footer-list-item'>License</li>
                        <li className='footer-list-item'>Refund and Policy</li>
                    </ul>
                </div>
                <div className="footer-copy">
                    <p> Mabine &copy;2023</p>
                </div>
                {/* <div className="footer_wrapper">
                    <div className="footer_brand">
                        <Logo/>
                        <div className="brand_des">
                         <p>Adipisicing incididunt consectetur sunt ut eiusmod culpa. Enim fugiat commodo incididunt dolore do duis qui eiusmod.</p>
                       </div>
                    </div>
                    <div className="footer_col">
                        <h4>Products</h4>
                        <ul className="footer-list">
                            <li className="footer_list_item"><a href="/">Treading</a></li>
                            <li className="footer_list_item"><a href="/">My Account</a></li>
                            <li className="footer_list_item"><a href="/">Brands</a></li>
                            <li className="footer_list_item"><a href="/">Sites</a></li>
                
                        </ul>
                    </div>
                    <div className="footer_col">
                        <h4>Legals</h4>
                        <ul className="footer-list">
                            <li className="footer_list_item"><a href="/">License</a></li>
                            <li className="footer_list_item"><a href="/">Refund Policy</a></li>
                            <li className="footer-list-item"><a href="/">About us</a></li>
                            <li className="footer-list-item"><a href="/">Blog</a></li>
                
                        </ul>
                    </div>
                    <div className="footer_col">
                        <h4>Conntact us</h4>
                        <p>Feel free to get in touch with us via phone or send us a message</p>
                        <ul className="footer_list">
                            <li className="footer_list_item"><a href="/">+1 234 567 89 10</a></li>
                            <li className="footer_list_item"><a href="/">support@mabine.com</a></li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </footer>
    )
}

export default Footer;