import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector} from "react-redux"
export default function ShopRow({shop}) {
    const navigate = useNavigate();
    const products = useSelector(state => state.feed)
    const {name, _id, state, address, city} = shop
    let promos = 0
    products['products'].forEach(element => {
        if(_id === element['_id']) promos += 1
    })
    const toShop = () => { navigate(`/Shop/${_id}/all/0`)}

    return (
        <div className="shop_row" onClick={toShop}>
            <img className="store_image" src={require('../../assets/store.jpeg')} alt="store" />
            <div>
                <p className="shop_name">{name}</p>
                <p className="shop_address">{address}, {city}, {state}</p>
                <p className="shop_promo">{promos > 0 ? promos : 'No'} Active Promotions</p>
            </div>
        </div>
    )
}
