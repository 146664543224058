import React from 'react';
import './MyShop.scss';
import { FiEdit3 } from 'react-icons/fi';
import { useDispatch, useSelector} from "react-redux";
// import {useNavigate } from "react-router-dom";
import { useState } from 'react';
import CreateShop from '../shop/createShop'
import MyShops from "../shop/MyShops";
import * as SERVICE from '../../services';
import httpClient from '../../services/ApiCallHandler';
import { toggle_loader } from '../../redux/actions/general';
import { userDataObjectFormatter } from '../../services/Helpers';
import { load } from '../../redux/actions/profile';
import GeneralProductsTab from './GeneralProductsTab';
import NavSlider from './NavSlider';


const MyShop = ()=>{
  const dispatch = useDispatch()
   const profile = useSelector((state:any) => state.profile) as any;
  //  let navigate = useNavigate();

   let profile_image =  require("../../assets/avatar.jpeg"); //profile.dp ||
   let profileCover = require("../../assets/banner.png") // profile.coverPhoto;
   console.log(profileCover)
   const [tab, set_tab] = useState("MYSHOPS");

   const uploadImage = (image: any,type:string) => {
    dispatch(toggle_loader(true))
      let obj = { file: image, src: "" } as any
      const reader = new FileReader();
      reader.onloadend = () => {
          obj['src'] = reader.result
      }
      reader.readAsDataURL(image);
      console.log(image)

      let formData = new FormData()
      formData.append("image", image)
      formData.append("userId", profile.id)
      formData.append("profileSource","internal")
      const config = SERVICE.updateUserProfilePhoto(formData,type)

      httpClient(config).then(function (response) {
      
          console.log(response)
          dispatch(load(userDataObjectFormatter(response["data"])))
          dispatch(toggle_loader(false))
       })
        .catch(function (error) {
            dispatch(toggle_loader(false))
            console.log(error);
        });
    }


    // const profileNav = ()=>{
    // return(
    // <nav className="profile-body-nav">
    //   <navSlider/>
    // </nav>
    // )
    // }


    return(
      <div className="profile_content">
        <div className="profile-information">
          <header className='profile-header'>
          <div className="profile-background-image">
              <div className="image-container">
                <img src={profileCover} alt="cover" />
              </div>
                <label className="profile-edit-icon"  htmlFor="coverPic"><FiEdit3 /><input type="file" id="coverPic" name="coverPic"accept="image/*" hidden onChange={(e:any) => uploadImage(e.target.files[0],"cover")}/></label>
            </div>
            <div className="profile-top-details">
                <div className="profile-picture-wrapper">
                  <div className="image-container">
                  <img src={profile_image} alt={profile.first_name} />
                  </div>
                  <label htmlFor="avatar" className="profile-edit-image-icon"><FiEdit3 /><input type="file" id="avatar" name="avatar"accept="image/*" hidden onChange={(e:any) => uploadImage(e.target.files[0],"profile")}/></label>
                </div>
                <div className="profile-name-wrapper" >
                  <h3 className="profile-name">
                    {profile.firstName} {profile.lastName}
                  </h3>
                  <p className="profile-email">{profile.email}</p>
                </div>
                <div className="profile-buttons">
                  <button
                    onClick={() => set_tab("CREATE")}
                    className="btn-secondary"
                  >
                    Create Shop
                  </button>
                  <button className="btn-primary">
                    Post
                  </button>
                </div>
            </div>
            <NavSlider tab={tab} set_tab={set_tab}/>
          </header>
          <main>
          <div className="profile-body">
              <div className="sub_profile_tabs">
                {tab === "WATCHLIST" ? (
                  <GeneralProductsTab type="WATCHLIST" userId={profile.id} page="myProfile"/>
                
                  ) : tab === "FAVOURITES" ? (
                    <GeneralProductsTab type="FAVOURITES" userId={profile.id}  page="myProfile" />
                  ) : tab === "CREATE" ? (
                    <CreateShop   page="myProfile"/>
                  ) : tab === "MYSHOPS" ? (
                    <MyShops   page="myProfile"/>
                  ) : tab === "SOLD" ? (
                    <GeneralProductsTab type="SOLD" userId={profile.id}  page="myProfile"/>
                  ) : 
                   tab === "EXPIRED" ? (
                    <GeneralProductsTab type="EXPIRED" userId={profile.id}  page="myProfile"/>
                  ) :(
                    <GeneralProductsTab type="SELLS" userId={profile.id} page="myProfile" />

                  )
                }                
              </div>

            </div>
          </main>  
        </div>
      </div>
    )
  }

  export default MyShop;
