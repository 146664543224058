import React from 'react';
import { useEffect } from "react";
import { get_shop_products } from "../../services";
import axios from "axios";
import { useState } from "react";
import { toggle_loader } from '../../redux/actions/general';
import { useDispatch, useSelector} from "react-redux"
import { set_all_Products } from "../../redux/actions/shop";
import Product from "../promo_cards/Product";

export default function AllPromos({shop_id}) {
    const dispatch = useDispatch()
    const all_products = useSelector(state => state.shops).all_products
    const [products, set_products] = useState(all_products)

    
    useEffect(()=>{
        if(products.length < 1){
            dispatch(toggle_loader(true))
        }
        let config = get_shop_products(shop_id)
        axios(config).then(function (response) {
            set_products(response['data']['products'])
            dispatch(toggle_loader(false))
            dispatch(set_all_Products(response['data']['products']))
        })
        .catch(function (error) {
            console.log(error);
            dispatch(toggle_loader(false))
        });
    },[shop_id, dispatch, products.length])

    const allowed_actions = ["Edit", "Delete", "Add to Watchlist"]

    return (
        <div className="shop_products">
            {products.map((product, index) => {
                return <Product product={product} allowed_actions={allowed_actions} index={index} key={index} />
            })}
        </div>
    )
}
