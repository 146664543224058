import { GET_ALL_SHOPS, SET_ACTIVE_PRODUCTS, SET_ALL_PRODUCTS, SET_PAST_PRODUCTS, SET_WATCHLIST, SET_FAVOURITES } from "../actions/shop";


const defaultState =  {
    shops:[],
    watchlist:[],
    all_products:[],
    active_products:[],
    past_products:[],
    watchlists:{
        _id:"",
        userId:"",
        watchlist: []
    },
    favourites:{
        _id:"",
        userId:"",
        favourite: []
    }
};

export const shops = (state = defaultState, action) => {
    switch (action.type) {
      case GET_ALL_SHOPS:{
        state.shops = action.payload
        return state
      }
      case SET_ALL_PRODUCTS:{
        state.all_products = action.payload
        return state
      }
      case SET_ACTIVE_PRODUCTS:{
        state.active_products = action.payload
        return state
      }
      case SET_PAST_PRODUCTS:{
        state.past_products = action.payload
        return state
      }
      case SET_WATCHLIST:{
        state.watchlists = action.payload
        return state
      }
      case SET_FAVOURITES:{
        state.favourites = action.payload
        return state
      }
      default: return state
    }
  }