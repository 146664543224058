import React from 'react';
import Footer from "../components/general/Footer";
import Navigation from "../components/navigation/Navigation";
import { useEffect, useState } from "react";
import { get_shop, get_shop_products } from "../services";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import Product from "../components/promo_cards/Product";
import { toggle_loader } from '../redux/actions/general';
import { set_all_Products } from '../redux/actions/shop';

export default function VisitShop() {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const profile = useSelector((state:any) => state.profile)

    let { id } = useParams();
    const [tab, set_tab] = useState("ALL")
    const [view_shop, set_view_shop] = useState({
        name: "",
        shop_picture: require("../assets/store.jpeg")
    })

    const view_on_map = () => {
        window.open(`http://maps.google.com/?q=${view_shop.name}`)
    }

    useEffect(() => {
        dispatch(toggle_loader(true))
        let config = get_shop(id)
        axios(config).then(function (response) {
            let shop = response['data']['shop']
            shop['shop_picture'] = shop['dp'] || require("../assets/product.jpg")
            shop['location'] = `${shop['name']} ${shop['address']} ${shop['city']} ${shop['state']}`
            set_view_shop(shop)
            dispatch(toggle_loader(false))
        })
    }, [dispatch, id, navigate, profile])

    const all_products = useSelector((state:any) => state.shops).all_products
    const [products, set_products] = useState(all_products)


    useEffect(() => {
        if (products.length < 1) {
            dispatch(toggle_loader(true))
        }
        let config = get_shop_products(id)
        axios(config).then(function (response) {
            set_products(response['data']['products'])
            dispatch(toggle_loader(false))
            dispatch(set_all_Products(response['data']['products']))
        })
            .catch(function (error) {
                console.log(error);
                dispatch(toggle_loader(false))
            });
    }, [id, dispatch, products.length])

    const allowed_actions = ["Add to Watchlist", "View Product", "Visit Store"]

    return (
        <>
            <Navigation />
                <div className="container">
                    <div className="profile_wrapper">
                        <div className="profile_content">
                            <div className="profile_information">

                                <div className="profile_information">
                                    <div className="profile_background_image" style={{ backgroundImage: `url(${profile.coverPhoto})`, backgroundRepeat: "none", objectFit: "cover" }}>
                                        <label className="profile_edit_icon" htmlFor="coverPic"></label>
                                        <div className="profile_picture_wrapper">
                                            <img src={view_shop.shop_picture} alt="pp" />
                                            <label htmlFor="avatar" className="profile_edit_image_icon"></label>

                                        </div>
                                    </div>
                                    <div className="profile_top_details">
                                        <div>
                                            <p className="shop_name" onClick={view_on_map}>{view_shop.name}</p>
                                            {/* <p className="shop_email" onClick={view_on_map}>{view_shop.address}, {view_shop.city}</p> */}
                                            <p className="shop_promo">{products.length || "No"} Active Promotions</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile_body">
                                    <div className="profile_body_nav">
                                        <button onClick={() => set_tab("ALL")} className={tab === 'ALL' ? 'profile_body_nav_active' : ''}>Active Promotions</button>
                                    </div>
                                    <div className="shop_products">
                                        {products.map(({product, index}:any) => {
                                            return <Product product={product} allowed_actions={allowed_actions} key={index} />
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
        )
}