import React from "react";
import { MdChevronLeft, MdLockOutline,MdOutlineLogin, MdOutlineLogout, MdOutlinePerson, MdOutlineSettings, MdStore} from "react-icons/md";
import './NavMenu.scss';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Categories from "../../assets/Categories";
import {Link} from 'react-router-dom';

function MenuNavbar (props:any){
  return(
    <div className="menu_navbar">
      <ul className="menu_navbar_nav">
        {props.children}
      </ul>
    </div>
  )
}

function NavItem (props:any){

  return(
    <li className="nav_item">
      <span className="ptr item" onClick={() => props.goToMenu && props.setActiveMenu(props.goToMenu)}>
        <div>
        <span className="icon-button">{props.leftIcon}</span>
          {props.children}
        </div>
        <span className="icon-right">{props.rightIcon}</span>
      </span>
    </li>
  )
}

const HeaderMenu = ({openMenu, setOpenMenu, logout, profile,BiX}:any)=>{
  const [activeMenu, setActiveMenu] = useState('main')

  const closeMenu = ()=>{
    setOpenMenu(false);
    document.body.removeAttribute('style');
  }

    return(
        <div className={openMenu ? "header_menu menu_display" : "header_menu"}>
          <div className="header_menu_container">
          <span
          className="close-modal"
          style={openMenu ? { display: "block" } : { display: "none" }}
          onClick={() => {
            setOpenMenu(false);
            document.body.removeAttribute('style');
          }}
        >
          <BiX />
        </span>

        <CSSTransition in={activeMenu === 'main'} unmountOnExit timeout={500} classNames="menu-primary">
        <MenuNavbar>
          <div className="menu-user">
            <h2>{profile.firstName} {profile.lastName}</h2>
            <span>{profile.email}</span>
          </div>
          <NavItem
          goToMenu="category"
          setActiveMenu={setActiveMenu}
          >Categories
          </NavItem>
          <h4>Account and Help</h4>
          {profile.logged_in === true  && (
          <div>
           <NavItem>
             <Link to="/profile/myshop" className="menu-link" onClick={closeMenu}>
               <MdStore/>
               <span>My Shop</span> 
              </Link>
           </NavItem>
           <NavItem>
             <Link to="/profile/account" className="menu-link" onClick={closeMenu}>
                <MdOutlinePerson/>
                <span>Account Details</span>
              </Link>
           </NavItem>
           <NavItem>
             <Link to="/profile/changepassword" className="menu-link" onClick={closeMenu} >
                <MdLockOutline/>
                <span>Change Password</span>
              </Link>
           </NavItem>
           <NavItem>
             <Link to="/profile/settings" className="menu-link" onClick={closeMenu}>
                <MdOutlineSettings/>
                <span>Settings</span>
              </Link>
           </NavItem>
         </div>
          ) }
          <NavItem>
          {profile.logged_in ? (
          <div className="menu-state">
            <span
            className="ptr menu-link"
              onClick={() => {
                setOpenMenu(false);
                document.body.removeAttribute('style');
                return logout();
              }}
            >
              <MdOutlineLogout/>
              <span> Sign out</span>
            </span>
          </div>
        ) : (
          <div className="menu-state">
            <Link to="/login" className="menu-link"
              onClick={() => {
                setOpenMenu(false);
                document.body.removeAttribute('style');
              }}>
              <MdOutlineLogin/>
              <span>Login</span>
              </Link>
          </div>
        )}
          </NavItem>
        </MenuNavbar>
        </CSSTransition>

        <CSSTransition in={activeMenu === 'category'} unmountOnExit timeout={500} classNames="menu-secondary">
        <MenuNavbar>
          <NavItem ClassName="return"  leftIcon={<MdChevronLeft /> }
          goToMenu="main"
          setActiveMenu={setActiveMenu}>
          </NavItem>
          <h3>Categories</h3>
          {Categories && Categories.map((category, index) =>{
            return <NavItem key={index}><Link to={`search?category=${category.code}`} onClick={closeMenu}>{category.name}</Link></NavItem>
          })}
        </MenuNavbar>
        </CSSTransition>

          </div>
      </div>
    )
}

export default HeaderMenu;