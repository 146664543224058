import React from 'react';
import { AiFillTag } from "react-icons/ai"
import {BiChevronRight} from "react-icons/bi"
import { useNavigate } from "react-router-dom";

export default function SidePromos({product}) {
    const {name, _id} = product
    const navigate = useNavigate();
    const toProduct = () => {
        let path = `/Product/${_id}`;
        navigate(path);
    }
    return (
        <div className="side_promos" onClick={toProduct}>
            <div className="promos">
            <AiFillTag />
            <div>
                <p className="side_promo_name">{name}</p>
                <p className="side_promo_promo">Ends in 3 days</p>
            </div>
            <BiChevronRight />
            </div>
        </div>
    )
}
