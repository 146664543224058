import React from 'react';
import Footer from "../components/general/Footer"
import Navigation from "../components/navigation/Navigation"

const Error = ()=>{
    return(
        <>
        <Navigation/>
        <div className="error_page">
            <h1>Oops!! Page Could Not Be Found</h1>
        </div>
        <Footer/>
        </>
    )
}

export default Error;