import axios from "axios";
import errorInterceptor from "../interceptors/error";
import updateHeaderInterceptor from "../interceptors/updateHeader";

const BASE =  process.env.REACT_APP_API_BASE_URL;
const API = `${BASE}/api/`
const httpClient = axios.create({
baseURL: API,
headers: {
    'X-Custom-Header': 'foobar'
}
});
errorInterceptor(httpClient);
updateHeaderInterceptor(httpClient);
export default httpClient;