import React from "react";
import {FormInput} from "../general/FormElements";

const SignupForm = ({formValidate,FcGoogle, loginWithSocial,Link, set_firstName, firstName, lastName, set_lastName, email, set_email, pass, set_pass, cpass, set_cpass}:any)=>{
    return(
        <form
        className="signup_form"
        id="signup"
        onSubmit={(e)=>formValidate(e)}
      >
        <div className="form_header">
          <h1>Signup to Mabine</h1>
          <p>See your growth and get consulting support!</p>
        </div>
        <div
          className="form_input google_auth ptr" 
          onClick={(e) => {loginWithSocial(e,"google")}}
        >
          <span className="auth_icon ptr">
          <FcGoogle className="ptr"/>
          </span>{" "}
          <span>
            Sign up with Google
          </span>
        </div>
        <hr className="form_hr" />

        <div className="form-group">
          <div className="form-control">
            <FormInput
             name="firstname"
             type="text"
             fun={firstName}
             funCall={set_firstName}>
              Firstname
            </FormInput>
          </div>
          <div className="form-control">
            <FormInput
             name="lastname"
             type="text"
             fun={lastName}
             funCall={set_lastName}>
              Lastname
            </FormInput>
          </div>
        </div>
        <div className="form-control">
            <FormInput
             name="email"
             type="email"
             fun={email}
             funCall={set_email}>
              Email
            </FormInput>
        </div>
        <div className="form-control">
          <FormInput
            name="password"
            type="password"
            fun={pass}
            funCall={set_pass}>
            Password
          </FormInput>    
        </div>
        <div className="form-control">
          <FormInput
            name="confirm_password"
            type="password"
            fun={cpass}
            funCall={set_cpass}>
            Confirm Password
          </FormInput>
        </div>
        <div className="agree">
          <p>
            <input type="checkbox" name="remember" /> Creating an account
            means you’re okay with our Terms of Service, Privacy Policy, and
            our default Notification Settings.
          </p>
        </div>
        <input
          type="submit"
          className="btn-primary"
          value="Sign In"
        />
         <div className="pad_nav">
           <div className="form-alt">
              <p>
                Already have an account?{" "}
                <Link to="/login"
                  className="main_color ptr" >
                  Login Here
                </Link>
              </p>
            </div>
        </div>
        
      </form>
    )
}

export default SignupForm;