import { GET_ALL_CHATS, get_all_chats } from "../actions/messages";

export const defaultState =  {
    chats:[]
};

export const chatLists = (state = defaultState, action) => {
    switch(action.type) {
      case GET_ALL_CHATS: {
        state = get_all_chats(state, action.payload);
        return state
      }  
      
      default: return state
  
    }
  }