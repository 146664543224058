import React from 'react';
import  './Product.scss';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi"
import { GrClose } from "react-icons/gr"
import { ProductActions, formatter } from '../../services/Helpers'
import { useDispatch, useSelector } from "react-redux";
import { toggle_loader } from "../../redux/actions/general";
import { MdFavoriteBorder } from 'react-icons/md';

export default function AdvertProduct({ product, index, allowed_actions, isMyProduct }) {
    const dispatch = useDispatch();
    const { view_product, action_btn, addDefaultSrc, action_btns } = new ProductActions()
    const profile = useSelector((state) => state.profile);
        const [hidden, setHidden] = useState(-1);

    const { _id, name, title, bidPrice, images, end, storeId, userId, } = product

    let start_data = new Date()
    let end_data = new Date(end)
    let ends_seconds = Math.ceil((end_data.getTime() - start_data.getTime()) / 1000)
    const [end_time, set_end_time] = useState(ends_seconds)

    const [open_actions, set_open_actions] = useState(false)


    if (allowed_actions && profile && profile.id === userId) {
        allowed_actions.push("Edit", "Delete")
    }
    useEffect(() => {
        let timer = setInterval(() => {
            set_end_time(ends_seconds - 60)
        }, 60000)
        return () => clearInterval(timer)
    }, [ends_seconds])



    return (
        <div className="product" onMouseEnter={()=> setHidden(_id) } onMouseLeave={()=> setHidden(-1)}>
            <figure className="product_image">
            <div className="product_top">
                    <button className={`${hidden === _id? "product_like_show" :"product_like_hidden"}`}>
                        <MdFavoriteBorder/>
                    </button>
                    <div className="product-type">Auction</div>
                </div>
                    <Link to={`/Product/${_id}`} className="product_image_cover">
                        <div className="image-container">
                        <img onClick={view_product} crossOrigin="anonymous" onError={(e) => addDefaultSrc(e)} src={images.length > 0 ? images[0]['Location'] : ""}  alt={name} />
                        </div>
                    </Link>
            </figure>
            <div className="product_details">
                <div className="details">
                    <p className="product_ends">{formatter(end_time,end_data, "Auction")}</p>
                    <p className="product_name">{name}</p>
                    <p className="product_desc">{title}</p>
                    <div className="product-price">
                      <p className="price">K {bidPrice}.00</p>
                    </div>
                </div>
                <div className="options">
                    <button className={ hidden === _id || open_actions? "options_show" :"options_hide"} onClick={() => set_open_actions(!open_actions)}>
                        {!open_actions ? <BiDotsVerticalRounded /> : <GrClose/>}
                    </button>
                    <div className="action_container">
                        <div className="action_row">
                            {allowed_actions && action_btns.map((element, index) => {
                                if (allowed_actions.includes(element) && open_actions) {
                                    return <button
                                        onClick={() =>  action_btn(element, storeId, _id, () => dispatch(toggle_loader(true)))}
                                        key={index}
                                        className="action_rows_btn">{element}
                                    </button>
                                }
                                else return ""
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
