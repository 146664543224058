
import { MdAdd, MdChatBubbleOutline, MdFavoriteBorder, MdHome,MdPersonOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

const TabBar = (props:any)=>{
    return(
        <nav className="tab-bar-nav md">
            <div className="container">
                <ul className="bar-list">
                    <li className="bar-list-item">
                        <Link to="/"className='tab-bar-icon'>
                            <MdHome/>
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="bar-list-item">
                        <Link to="/"className='tab-bar-icon'>
                            <MdFavoriteBorder/>
                            <span>Wishlist</span>
                        </Link>
                    </li>
                    <li className="bar-list-item">
                        <button  onClick={() => { props.setModalState(true) }} className='tab-bar-icon middle-icon'>
                            <MdAdd/>
                        </button>
                    </li>
                    <li className="bar-list-item">
                        <Link to="/messages"className='tab-bar-icon'>
                            <MdChatBubbleOutline/>
                            <span>Messsages</span>
                        </Link>
                    </li>
                    <li className="bar-list-item">
                            {props.profile.logged_in?

                            (
                                <Link to="/profile/myshop" className='tab-bar-icon'>
                                {/* <div className="user-icon">
                                    <div className="image-container">
                                        <img  src={props.profile_image} alt="user" />
                                    </div>
                                </div> */}
                                <MdPersonOutline/>
                                <span>{props.profile.firstName}</span>
                                
                                </Link>

                            ):(
                                <Link to="/login" className='tab-bar-icon'>
                                <MdPersonOutline/>
                                <span>Log In</span>
                                </Link>
                            )
                             }
                    </li> 
                </ul>
            </div>
        </nav>
    )
}


export default TabBar;