import './NavList.scss';
import { Link} from "react-router-dom";
import ProfileMenu from './ProfileMenu';
import { MdChatBubbleOutline,MdFavoriteBorder, MdNotificationsNone} from "react-icons/md";
import NotificationMenu from './NotifiationMenu';
import CreateAdModal from '../general/CreateAdModal';


const NavList = (props:any)=>{
    return(
        <ul className="nav-list">
          <li className="nav-list-item">
          <div className="post-modal lg">
               <button className="btn-secondary create-ad" id="open_modal" onClick={() => { props.setModalState(true) }}>Create Advert</button>
               <CreateAdModal setModalState={props.setModalState} modalState={props.modalState}/>
            </div>
          </li>
          <li className="nav-list-item notifications">
            <span className="icon notification_icon">
              <MdNotificationsNone  className="close_menu"/>
            </span>
            <NotificationMenu/>
          </li>
          <li className="nav-list-item">
            <span className="icon wishlist_icon">
                < MdFavoriteBorder/>
            </span>
          </li>
          <li className='nav-list-item'>
            <Link to="/messages" className="icon chat_icon">
                  <MdChatBubbleOutline />
                  <span className="counter" style={{display:(props.messageCounter === 0)? 'none' : 'block'}}>{props.messageCounter}</span>
            </Link>
          </li>
          <li className="nav-list-item user-profile">
                <button className="icon user-img">
                    <div className="image-container">
                      <img  src={props.profile_image} alt="user" />
                    </div>
                  </button>
                  <ProfileMenu
                  profile_image ={props.profile_image}
                  Link = {Link}
                  profile ={props.profile}
                  logout={props.logout} />
          </li>
        </ul>
    )
}

export default NavList;