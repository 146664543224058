import React from 'react';
import  './Product.scss';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductActions, formatter } from '../../services/Helpers'
import {GrClose} from "react-icons/gr"
import { MdFavoriteBorder } from 'react-icons/md';
import { BiDotsVerticalRounded } from "react-icons/bi"


export default function PastProduct({ product }) {
    const { view_product, addDefaultSrc } = new ProductActions()

    const { _id, name, title, oldPrice, images, newPrice, end } = product
    // let percentage = 100 - Math.floor((+newPrice / +oldPrice) * 100) || 0

    let start_data = new Date()
    let end_data = new Date(end)
    let ends_seconds = Math.ceil((end_data.getTime() - start_data.getTime()) / 1000)
    const [end_time, set_end_time] = useState(ends_seconds)

    const [open_actions, set_open_actions] = useState(false)
    const [hidden, setHidden] = useState(-1);


    useEffect(() => {
        let timer = setInterval(() => {
            set_end_time(ends_seconds - 60)
        }, 60000)
        return () => clearInterval(timer)
    }, [ends_seconds])

    return (
        <div className="product" onMouseEnter={()=> setHidden(_id) } onMouseLeave={()=> setHidden(-1)}>
        <figure className="product_image">
        <div className="product_top">
                <button className={`${hidden === _id? "product_like_show" :"product_like_hidden"}`}>
                    <MdFavoriteBorder/>
                </button>
                {/* <div className="product-type">Promotion</div> */}
            </div>
                <Link to={`/Product/${_id}`} className="product_image_cover">
                    <div className="image-container">
                    <img onClick={view_product} crossOrigin="anonymous" onError={(e) => addDefaultSrc(e)} src={images.length > 0 ? images[0]['Location'] : ""}  alt={name} />
                    </div>
                </Link>
        </figure>
        <div className="product_details">
                <div className="details">
                    <p className="product_ends">{formatter(end_time, end_data, "Promo")}</p>
                    <p className="product_name">{name}</p>
                    <p className="product_desc">{title}</p>
                    <div className="product-price">
                        <p className="product_old"> K{oldPrice} </p>
                        <p className="product_discount price">K{newPrice}.00</p>
                        {/* <p className="product_percentage">{percentage}% off</p> */}
                    </div>
                </div>
                <div className="options">
                    <button className={ hidden === _id || open_actions? "options_show" :"options_hide"} onClick={() => set_open_actions(!open_actions)}>
                        {!open_actions ? <BiDotsVerticalRounded /> : <GrClose/>}
                    </button>
                    <div className="action_container">
                    <button>Restore</button>
                    </div>
                </div>
            </div>
        </div>

    )
}



