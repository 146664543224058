export const TOGGLE_LOADER =  "TOGGLE_LOADER";
export const SET_ALEART =  "SET_ALEART"

export const MODAL_OPEN = "MODAL_OPEN"

export const toggle_loader = (data) => {
    return { type: TOGGLE_LOADER, payload:data}
};

export const display_alert = (data) => {
    return { type: SET_ALEART, payload:data}

}

export const modal_open = (data) =>{
    return{type:MODAL_OPEN, payload:data}
}