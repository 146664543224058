import React from 'react';
import "../css/Search.scss";
import Filter from "../components/search/Filter";
import Navigation from "../components/navigation/Navigation";
import Product from "../components/promo_cards/Product";
import { MdNavigateBefore, MdNavigateNext, MdTune, MdViewComfy, MdViewList, MdViewModule } from "react-icons/md";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState,useCallback } from "react";
import { get_all_products } from "../services";
import { FaBoxOpen } from "react-icons/fa";
import ReactPaginate from 'react-paginate';

import AdvertProduct from '../components/promo_cards/AdvertProduct';
import AuctionProduct from '../components/promo_cards/AuctionProduct';
import PromotionProduct from '../components/promo_cards/PromotionProduct';
import OneTimeSellProduct from '../components/promo_cards/OneTimeSellProduct';
import { all_products } from '../redux/actions/feed';
import { set_favourites, set_watchlist } from '../redux/actions/shop';
import Footer from '../components/general/Footer';

export default function Search() {

  const dispatch = useDispatch();
  const old_products = useSelector((state:any) => state.feed);
  const [products, set_products] = useState(old_products.products);
  const [no_promos, set_no_promos] = useState(false);
  const [sortBy,setSortBy] = useState("new");
  const profile = useSelector((state:any) => state.profile)


  const [pageNumber, setPageNumber] = useState(0)
  const productsPerPage = 12;
  const visitedPages = pageNumber * productsPerPage;
  const pageCount = Math.ceil(products.length/ productsPerPage)

  const changePage = ({selected}:any) =>{
    setPageNumber(selected)

}


  const handleSortBy = useCallback(()=> {
    let sortData: string;
    let sortByCache = localStorage.getItem("productsSortBy");
    console.log(sortByCache,"chcek ocal")
    if(sortByCache){
      sortData =   sortByCache;
    } else {
      sortData =  sortBy;
    } 


    let newSortedProducts = products.sort((a:any,b:any) => {
     return  sortData === "new"?  ( new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf() ) : ( new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf() )
    });
    set_products(newSortedProducts)


  },[products, sortBy]);
  
  useEffect(()=>{
    // let date = (new Date()).getTime()
    let id = '';
    if(profile && profile.id){
      id = profile.id
    }
    get_all_products(id).then(function (response:any) {
        let new_products = response['data']['products']['products'];
        let userWatchlist = response["data"]["products"]['userWatchlist'].length > 0 ? response["data"]["products"]['userWatchlist'][0] : [];
        let userFavourites = response['data']['products']['userFavourites'].length > 0 ? response['data']['products']['userFavourites'][0] : [];
        // filter(element => {
        //     let start = (new Date(element['start'])).getTime()
        //     let end = (new Date(element['end'])).getTime()
        //     return end > date && start < date
        // }).reverse()
        dispatch(all_products(new_products))
        dispatch(set_watchlist(userWatchlist));
        dispatch(set_favourites(userFavourites));
        set_products(new_products)
       
        if(new_products.length < 1) set_no_promos(true)
    })
},[dispatch, profile])

  const allowed_actions = ["Add to Watchlist", "View Product", "Visit Store"];

  const [col, setCol] = useState("products_grid_1");

  const [modalDisplay, setModalDisplay] = useState(false);
  const handleSort = (e:any) => {
    setSortBy(e); 
    localStorage.setItem("productsSortBy",sortBy);
    handleSortBy();
  }
  useEffect(() => {
    handleSortBy()
  
    
  }, [handleSortBy])
  


  return (
    <div>
      <Navigation />
      <main id='search-page'>
        <div className="container">
          <div className="main_area">
            <Filter modalDisplay={modalDisplay} setModalDisplay={setModalDisplay} />
            <div className="products">
              <div className="sort_panel">
                <div className="search_results">
                  <p>{products.length} results found</p>
                </div>
                <select name="sort" id="sortBy"  value={sortBy} onChange={(e) => {handleSort(e)}}>
                  <option value="new">Newest</option>
                  <option value="older">Older</option>
                </select>
                <div className="view_menu">
                  <button
                    onClick={() => setCol("products_grid_4")}
                    className={col === "products_grid_4" ? "active_grid" : ""}
                    name="4"
                  >
                    <MdViewComfy />
                  </button>
                  <button
                    onClick={() => setCol("products_grid_3")}
                    className={col === "products_grid_3" ? "active_grid" : ""}
                    name="3"
                  >
                    <MdViewModule />
                  </button>
                  <button
                    onClick={() => setCol("products_grid_1")}
                    className={col === "products_grid_1" ? "active_grid" : ""}
                    name="1"
                  >
                    <MdViewList />
                  </button>
                </div>
              </div>
              <button onClick={()=>{
                document.body.setAttribute("style","height:100vh; overflow:hidden;");
                setModalDisplay(true);
              }} className="filter_btn mb">
              <MdTune/> Filter
            </button>
              <div
                className={
                  col === "products_grid_1"
                    ? "details_panel"
                    : "details_panel_hide"
                }
              >
                <div className="item_name">Item Name</div>
                <div className="item_price">Price</div>
                <div className="item_date">Date</div>
                <div className="item_city_town">City/Town</div>
              </div>
              <div className={col}>
                <div
                  className="no_active_promos"
                  style={no_promos ? {} : { display: "none" }}
                >
                  <FaBoxOpen className="no_active_icons" />
                  <p className="no_active_text">No Active Promotions</p>
                </div>
                {products.slice(visitedPages, visitedPages + productsPerPage).map((product:any, index:any)=> {
                  if( product.type === "advert"){
                      return <AdvertProduct product={product} allowed_actions={allowed_actions} key={index}/>   
                  } 
                  else if(product.type === "auction") {
                      return <AuctionProduct product={product} allowed_actions={allowed_actions} key={index} index={undefined} isMyProduct={undefined}/>   

                  }
                   else if(product.type === "one_time_sell") {
                      return <OneTimeSellProduct product={product} allowed_actions={allowed_actions} key={index}/>   

                  }
                  else if(product.type === "promotion") {
                      return <PromotionProduct product={product} allowed_actions={allowed_actions} key={index} index={undefined} isMyProduct={undefined}/>   

                  } else {
                      return <Product product={product} allowed_actions={allowed_actions} key={index}/>   

                  }
                })}
                  <ReactPaginate
                    previousLabel={<MdNavigateBefore/>}
                    nextLabel={<MdNavigateNext/>}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    activeClassName='active-page'
                    containerClassName='pagination-list'
                    nextClassName='next-page'
                    previousLinkClassName='previous-page'
                    disabledClassName='disabled-page'

                    />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  );
}
