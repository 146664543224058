import React from "react"
import AdvertProduct from '../components/promo_cards/AdvertProduct';
import AuctionProduct from '../components/promo_cards/AuctionProduct';
import { useNavigate } from "react-router-dom";

import PromotionProduct from '../components/promo_cards/PromotionProduct';
import OneTimeSellProduct from '../components/promo_cards/OneTimeSellProduct';
import Product from "../components/promo_cards/Product";
import httpClient from "./ApiCallHandler";

export const Formatter = ({time, right_now, start_date, end_date}:any) => {
    let units = " min"
        let prefix = "Ends in "
        if(right_now < start_date){
            time = (start_date - right_now)/1000
            prefix = "Starts in "
        }
        if(time > 86400){
            time = Math.ceil(time/60)
            time = Math.ceil(time/60)
            time = Math.ceil(time/24)
            units = " days"
        }
        else if(time > 3600){
            time = Math.ceil(time/60)
            time = Math.ceil(time/60)
            units = " hours"
        }
        else{
            time = Math.ceil(time/60)
            units = " minutes"
        }
        if(time > 0) return prefix + time + units
        
        else return 'Ended on ' + end_date.toDateString()
}

export const MoneyFormatter = (money:any, currency:any) => {
    if(!money) return 0.00 + " " + currency
    if(isNaN(money)) return 0.00 + " " + currency
    if(typeof money !== 'number') return 0.00 + " " + currency
    let amount = (money).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return amount + " " + currency
}

export const TrimLongText = ({text, max}:any) => {
    if(!text) return ""
    if(text.length < max) return text
    return text.substring(0, max) + "..."
}
export const HttpExceptionHandler = (err:any) => {
     //setErrors(destrucureErors.response.data.errors)
     let errors;
     if (err.response) {
        // The client was given an error response (5xx, 4xx)
        errors = err.response.data;
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
    } else if (err.request) {
        // The client never received a response, and the request was never left
    } else {
        // Anything else
    }

    return errors;
}

export const userDataObjectFormatter = (object:any) => {
    const profile = object;

    let coverPhoto = '' ;
    let profilePhoto = '';
  //  const BASE =  process.env.REACT_APP_EXTERNAL_FILE;
    if (profile.provider === "google" && profile.profilePhotoSource === "external") {
        console.log(typeof profile.profilePhoto)
        if(typeof profile.profilePhoto !== "string"){
            profilePhoto = profile.profilePhoto.length > 0 ? profile.profilePhoto[0].value : '';
        } else {
            profilePhoto = profile.profilePhoto;

        }
    } else {
        profilePhoto = profile.profilePhoto.length > 0 ? profile.profilePhoto[0].Location : '';
    } 
    coverPhoto = profile.profileCoverPhoto && profile.profileCoverPhoto.length > 0 ? profile.profilePhoto[0].Location : ''

    return  {
        logged_in: true,
        isEmailVerified:profile.isEmailVerified, 
        id: profile._id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        message: profile.message,
        about: profile.about,
        profilePhoto:profilePhoto,
        coverPhoto:coverPhoto,
        dateOfBirth: profile.dateOfBirth,
        profilePhotoSource:profile.profilePhotoSource,
        location: profile.location,
        country: profile.country,
        city: profile.city,
        profession: profile.profession,
        title: profile.title,
        dp: profilePhoto ,
        phone:profile.phone
      };
}
export const sortChats = (data:any[], myId:any) => {
    let chatListIds:any[] = [];
    data.forEach((Element:any) => {
        if ((Element.toUserId === myId) && (Element.userId === Element.fromUserId)) {
            if (!chatListIds.some(x => x.id === Element.fromUserId)) {
                console.log(Element.senderData[0].firstName, "receivers name")
                let name = Element.senderData.length !== 0 ? Element.senderData[0].firstName + ' ' + Element.senderData[0].lastName : "Deleted User"
                let userProfile = Element.senderData.length !== 0 ? Element.senderData[0].profilePhoto : null
                chatListIds.push({ id: Element.fromUserId, incoming: true, name: name, message: Element.message, time: Element.createdAt, seen: Element.seen, profilePhoto:userProfile })

            }

        } else if ((Element.fromUserId === myId) && (Element.userId === Element.fromUserId)) {
            if (!chatListIds.some(x => x.id === Element.toUserId)) {
                let name = Element.receiverData.length !== 0 ? Element.receiverData[0].firstName + ' ' + Element.receiverData[0].lastName : "Deleted User"
                let userProfile = Element.receiverData.length !== 0 ? Element.receiverData[0].profilePhoto : null
                chatListIds.push({ id: Element.toUserId, incoming: false, name: name, message: Element.message, time: Element.createdAt, seen: Element.seen, profilePhoto:userProfile })

            }

        }
    })
    return chatListIds;
}
export const POST_TYPES = [ {slug:"auction", value:"Auction", key:1}, {slug:"promotion", value:"Promotion", key:2}, {slug:"one_time_sell", value:"One Time Sell", key:3},{slug:"advert", value:"Advert", key:4}, {slug:"auctiotheron", value:"Other", key:5}];

export const productCardFilters = (type:any,product:any,allowed_actions:any,index:any) => {

    if( type === "advert"){
        return <AdvertProduct product={product} allowed_actions={allowed_actions} key={index}/>   
    } 
    else if(type === "auction") {
        return <AuctionProduct product={product} allowed_actions={allowed_actions} key={index} index={undefined} isMyProduct={undefined}/>   

    }
     else if(type === "one_time_sell") {
        return <OneTimeSellProduct product={product} allowed_actions={allowed_actions} key={index}/>   

    }
    else if(type === "promotion") {
        return <PromotionProduct product={product} allowed_actions={allowed_actions} key={index} index={undefined} isMyProduct={undefined}/>   

    } else {
        return <Product product={product} allowed_actions={allowed_actions} key={index}/>   

    }
}

export class ProductActions  {
     navigate = useNavigate();

    
    
     delete_promo  (_id:any,config:any, lodder:any){
         lodder()

        httpClient(config).then(function () {
            window.location.reload()
        })
        .catch(function (error) {
            window.location.reload()
        });
    }
    watchlist_product()  {

    }
    visit_store (storeId:any) {
       return this.navigate(`/Store/${storeId}`)
    }

    end_product (storeId:any) {

    }
    edit_product (storeId:any,_id:any)  {
 
        return this.navigate(`/Shop/${storeId}/update/${_id}`)
    }
    view_product (_id:any) {
        return this.navigate(`/Product/${_id}`)
    }

    action_btn = (action:any, storeId:any,_id:any, endproductFunc:any) => {
        console.log(action);
        let endProductfunc  = endproductFunc
        if (action === "End") this.end_product(_id)
        else if (action === "Restart") this.edit_product(storeId, _id)
        else if (action === "Edit") this.edit_product(storeId, _id)
        else if (action === "Delete") this.delete_promo(_id, endProductfunc,'' )
        else if (action === "Add to Watchlist") this.watchlist_product()
        else if (action === "View Product") this.view_product(_id)
        else if (action === "Visit Store") this.visit_store(storeId)
    }
     addDefaultSrc(ev:any){
        ev.target.src = require("../assets/product.jpg")
        ev.target.onerror = null;
    }

    action_btns = [
        "End", "Restart", "Edit", "Delete", "Add to Watchlist", "View Product", "Visit Store"
    ]
    

}
 export const formatter = (time:any, end_data:any,type:any) => {
    let units = " min"
    if(time > 86400){
        time = Math.ceil(time/60)
        time = Math.ceil(time/60)
        time = Math.ceil(time/24)
        units = " days"
    }
    else if(time > 3600){
        time = Math.ceil(time/60)
        time = Math.ceil(time/60)
        units = " hours"
    }
    else{
        time = Math.ceil(time/60)
        units = " minutes"
    }

    if(time > 0) return `${type} ends in ` + time + units
    else return `${type} on ` + end_data.toDateString()
}