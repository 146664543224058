import React from 'react';
import  './Product.scss';
import { Link } from "react-router-dom";
import { useState } from "react";
// import { AiFillHeart } from "react-icons/ai"
// import {  Formatter, TrimLongText } from "../../services/Helpers"; //Formatter, MoneyFormatter
// import { HiBadgeCheck } from "react-icons/hi"
import { BiDotsVerticalRounded } from "react-icons/bi"
import {GrClose} from "react-icons/gr"
import { useNavigate } from "react-router-dom";
import { toggle_loader } from "../../redux/actions/general";
import { useDispatch,useSelector } from "react-redux";
import * as SERVICE from "../../services";
import httpClient from '../../services/ApiCallHandler';
import { MdFavoriteBorder } from 'react-icons/md';

export default function Product({ product, allowed_actions}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const [hidden, setHidden] = useState(-1);
 
    const { _id, name,title, images, newPrice,  storeId,userId } = product
    // let percentage = 100 - Math.floor((+newPrice / +oldPrice) * 100) || 0
    // let right_now = (new Date()).getTime()
    // let start_date = (new Date(start)).getTime()
    // let end_date = new Date(end)
    // let ends_seconds = Math.ceil((end_date.getTime() - right_now) / 1000)
    // const [end_time, set_end_time] = useState(ends_seconds)
    const [open_actions, set_open_actions] = useState(false)
    const [sold, setSold] = useState(false)

    // useEffect(() => {
    //     if(onSell === "sold"){
    //         setSold(true)
    //     }
    //     let timer = setInterval(() => {
    //         set_end_time(ends_seconds - 60)
    //     }, 60000)
    //     return () => clearInterval(timer)
    // }, [ends_seconds, onSell])

    const action_btns = [
        "End", "Restart", "Edit", "Delete", "Add to Watchlist", "View Product", "Visit Store"
    ]
    if(allowed_actions && profile && profile.id === userId){
        allowed_actions.push("Edit", "Delete")
    }

    const action_btn = (action) => {
        if (action === "End") end_product()
        else if (action === "Restart") edit_product()
        else if (action === "Edit") edit_product()
        else if (action === "Delete") delete_promo()
        else if (action === "Add to Watchlist") watchlist_product()
        else if (action === "View Product") view_product()
        else if (action === "Visit Store") visit_store()
        else if (action === "Mark Sold") markSold(_id)
    }

    const end_product = () => {

    }
    const markSold = (id) => {
        toggle_loader(true)
        let config = SERVICE.markSold(id,{status:"sold"})
        
        httpClient(config).then(function (response) {
            toggle_loader(false)
            setSold(true)
        })
        .catch(function (error) {
            window.location.reload()
        });
    }
    const edit_product = () => {
        navigate(`/Shop/${storeId}/update/${_id}`)
    }
    const delete_promo = () => {
        let config = SERVICE.delete_product(_id)
        dispatch(toggle_loader(true))
        httpClient(config).then(function (response) {
            window.location.reload()
        })
        .catch(function (error) {
            window.location.reload()
        });
    }
    const watchlist_product = () => {

    }
    const view_product = () => {
        navigate(`/Product/${_id}`)
    }
    const visit_store = () => {
        navigate(`/Store/${storeId}`)
    }

    function addDefaultSrc(ev){
        ev.target.src = require("../../assets/product.jpg")
        ev.target.onerror = null;
    }
    

    return (
        <div className="product" onMouseEnter={()=> setHidden(_id) } onMouseLeave={()=> setHidden(-1)}>
           <figure className="product_image">
            <div className="product_top">
                    <button className={`${hidden === _id? "product_like_show" :"product_like_hidden"}`}>
                        <MdFavoriteBorder/>
                    </button>
                </div>
                        <div className="image-container">
                        <Link to={`/Product/${_id}`} className="product_image_cover">
                        <img onClick={view_product} crossOrigin="anonymous" onError={(e) => addDefaultSrc(e)} src={images.length > 0 ? images[0]['Location'] : ""}  alt={name} />
                        </Link>
                        </div>
            </figure>
            {/* <div className="col_name">
            <p className="product_ad">Sponsored Ad  <span className="product_ad_sell_status" >{sold? 'Sold' : ''}</span></p>
                <div className="product_image">
                    <div className="image-container ptr">
                       <img onClick={view_product} crossOrigin="anonymous" onError={(e) => addDefaultSrc(e)} src={images.length > 0 ? images[0]['Location'] : ""} className="product_pic" alt={name} />
                    </div>
                </div>
                <div className="product_description">
                <h4 className="product_name ptr" onClick={view_product}>
                    <TrimLongText text={name} max={25} />
                </h4>
                <p className="product_desc">
                    <TrimLongText text={title} max={60} />
                </p>
                </div>

            </div> */}
            <div className="product_details">
                <div className="details">
                    <p className="product_ad">Sponsored Ad  <span className="product_ad_sell_status" >{sold? 'Sold' : ''}</span></p>
                    <p className="product_name">{name}</p>
                    <p className="product_desc">{title}</p>
                    <div className="product-price">
                        <p className="price">K{newPrice}.00</p>
                        {/* <p className="product_percentage">{percentage}% off</p> */}
                    </div>
                </div>
                <div className="options">
                    <button className={ hidden === _id || open_actions? "options_show" :"options_hide"} onClick={() => set_open_actions(!open_actions)}>
                        {!open_actions ? <BiDotsVerticalRounded /> : <GrClose/>}
                    </button>
                    <div className="action_container">
                        <div className="action_row">
                            {allowed_actions && action_btns.map((element, index) => {
                                if (allowed_actions.includes(element) && open_actions) {
                                    return <button
                                        onClick={() =>  action_btn(element, storeId, _id, () => dispatch(toggle_loader(true)))}
                                        key={index}
                                        className="action_rows_btn">{element}
                                    </button>
                                }
                                else return ""
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
