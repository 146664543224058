import httpClient from "./ApiCallHandler";
import Session from "./Session";

const BASE =  process.env.REACT_APP_API_BASE_URL;
const GoogleApi =  process.env.REACT_APP_GOOGLE_MAPS_API;


const API = `${BASE}/api/`

export const auto_login = () => {
    return {
        method: 'get',
        url: `${API}auth/get-session`,
        headers: { 
            'refreshToken': Session({},"GET").refreshToken, 
            'accessToken': Session({},"GET").accessToken, 
            'Content-Type': 'application/json'
        },
        data : {}
    };
}
export const register_new_user = (data) => {
    return {
        method: 'post',
        url: `${API}user/register`,
        headers: {'Content-Type': 'application/json'},
        data : data
    };
}

export const login = (data) => {
    return {
        method: 'post',
        url: `${API}auth/login`,
        headers: {'Content-Type': 'application/json'},
        data : data
    };
}
export const socialLogin = async (data) => {
    let newObj = {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        data : data
    };

    try {
        
        return await httpClient.post(`auth/login/social/${data}`, newObj);
      } catch (error) {
        // this failed, so let's redirect to the login page
        console.log(error);
      }
}


export const logout = (data) => {
    return {
        method: 'post',
        url: `${API}auth/logout`,
        headers: {'Content-Type': 'application/json'},
        data : data
    };
}

export const get_all_products = async (id) => {
    let newId = id? id : ''
    try {
        
        return await httpClient.post(`products/get-all`,{userId:newId});
      } catch (error) {
        // this failed, so let's redirect to the login page
        console.log(error);
      }
}

export const create_shop = (data) => {
    return {
        method: 'post',
        url: `${API}shops/create`,
        headers: { 
          'x-refresh': Session({},"GET").refreshToken, 
          'Authorization': Session({},"GET").accessToken, 
          'Content-Type': 'application/json'
        },
        data : data
    };
}

export const get_all_shops = () => {
    return {
        method: 'get',
        url: `${API}shops/get-all`,
        headers: { }
      };
}
export const getUserShops = (id) => {
    return {
        method: 'get',
        url: `${API}shops/get-my-shops/${id}`,
        headers: { }
      };
}


export const get_shop = (id) => {
    return {
        method: 'get',
        url: `${API}shops/get-single/${id}`,
        headers: { }
    };
}

export const create_post = (data) => {
    return {
        method: 'post',
        url: `${API}products/create`,
        headers: { 
          'x-refresh': '', 
          'Authorization': 'Bearer LS0tLS1CRUdJTiBSU0EgUFJJVkFURSBLRVktLS0tLQpNSUlDV3dJQkFBS0JnSE5kRi80UVU1TU1GYXZ5b295ZWlPRWp2V1dub0RCcnpiaVc2OXZwM0tTbnhBeE9jVTA5CmY4OVR3QlZNZGw3YlQzMTM3S0xYSXpQT3k4SXhNMTErNzBWaW5uODM1LzFlZWg4WUxwUTNuSmliWXlQanc0Yy8KL0ZjMmhkaEQ2Um8zb2FTblp1Y215K2plR1NQUzFjQmdtOFU5RHc5UGpGNHhPNlI1OEZKK2FyQUpBZ01CQUFFQwpnWUJWZ3RHLzd0WWtEemNaSjFhNXFPR0pFSGJaSFBFdU14cGdFdTV0S2VIMDRxMzgrVUFlb3RGVUdwNHNxMnFxClVTR1F2UTNZZm1PSGlXZWJYK0RWRXJ2RHlwcDA4alQ3VlMvQlpQbkRNYzVrSldRZTROWmRvZVAxOXlSblpnb3UKbFhiNXhZTklqVW5UeXNhVG5SYW9Sd0JOYjVub0NmbGZham5nMFdHZng4czRBUUpCQU5vTG1iYjVKdnZabWhJVgpPajEySGtGOU96RDhwN0ZBWUdicnovNEVybGVnR0J1enc4dkowakYyR1R0WTgvT3duaVQ1b1pndWpTOFFNTW1XCldNendtSWtDUVFDSGNkdFVUQ2lvWE9JTE54cXk0ZmhGVFZBa0ZVbGFyamlWYmt6UGlIZ2xLT0I0bmdRZmluZWQKcGJ6TXM0WTJJVUErRDlnckcwZy9MWEx2Rk94ZnhIdUJBa0VBaXlyZVNFZUwxekVTd2pua096ZzVwV2p0cjUyQwoxb0lWUlh0Ni9GRTJpVno0Smk0OWFNTitzZTBEdTdwUnNoYm5TUWcvV0dkVjIxVUZIcFVrUm1IRnFRSkFORjYxCnEzWjNFZ0kzOUpZdDRKUzI1alRxazhrWFA5UzFWREg5eVJDL1E3NzJiQ3pNODFVYXd2M2VibGZMd1FwQ1NMemYKbkd0RUdBbCtzeXFuNnErU0FRSkFERDZOVDV1TFM0WTRWWHVQT0lDWU9rTlg2RkxVbmYrTWRVb3ByZnNDdTU0Qgo1OFNBZUh0c0pmZ3hBS3BBaVA0UmNTT0l2UlE4UHZxak10RVA3d2xQVHc9PQotLS0tLUVORCBSU0EgUFJJVkFURSBLRVktLS0tLQ', 
          'Content-Type': 'application/json'
        },
        data : data
    };
}

export const edit_post = (data) => {
    return {
        method: 'post',
        url: `${API}products/update`,
        headers: {},
        data : data
    };
}

export const get_shop_products = (id) => {
    return {
        method: 'get',
        url: `${API}products/get-shop-products/${id}`,
        headers: { }
    };
}

export const view_product = (id) => {
    return {
        method: 'get',
        url: `${API}products/get-single/${id}`,
        headers: { }
    };
}

export const delete_shop = (id) => {
    return {
        method: 'delete',
        url: `${API}shops/delete/${id}`,
        headers: { }
    };
}
export const markSold = (id, data) => {
    return {
        method: 'put',
        url: `${API}products/status/${id}`,
        headers: { },
        data:data
    };
}

export const delete_product = (id) => {
    return {
        method: 'delete',
        url: `${API}products/delete/${id}`,
        headers: { }
    };
}
export const updateUserProfile = (data) => {
    return {
        method: 'put',
        url:`${API}user/profile-info-update`,
        headers:{},
        data:data
    }
}
export const updateUserProfilePhoto = (data,type) => {
    return {
        method: 'post',
        url:`${API}user/profile-${type}-upload`,
        headers:{},
        data:data
    }
}
export const updateUserPassword = (data) => {
    return {
        method: 'put',
        url:`${API}auth/change-password`,
        headers:{},
        data:data
    }
}
export const fetchUserMessages = (id) => {
    return {
        method: 'get',
        url:`${API}messages/get-user-messages/${id}`,
        headers:{},
    }
}
export const fetchUseConversation = (receiver,myProfile) => {
    return {
        method: 'get',
        url:`${API}messages/get-user-conversation/${receiver}/${myProfile}`,
        headers:{},
    }
}
export const sendMessage = (data) => {
    return {
        method: 'post',
        url:`${API}messages/send`,
        headers:{},
        data:data
    }
}
export const updateUserWatchlist = (data) => {
    return {
        method: 'put',
        url:`${API}products/update-user-watchlist`,
        headers:{},
        data:data
    }
}
export const getUserWatchlist = (id) => {
    return {
        method: 'put',
        url:`${API}products/get-user-watchlist/${id}`,
        headers:{},
    }
}
export const updateUserFavorite = (data) => {
    return {
        method: 'put',
        url:`${API}products/update-user-favourite`,
        headers:{},
        data:data
    }
}
export const getUserFavorite = (id) => {
    return {
        method: 'get',
        url:`${API}products/get-user-favourite/${id}`,
        headers:{},
    }
}

export const getPlaceAutoComplete = (text) => {
    return  {
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${text}&types=geocode&key=${GoogleApi}`,
        headers: { }
      };
}


