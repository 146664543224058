import  './FormElements.scss';

export function FormInput (props:any){
    return(
      <>
      <label htmlFor={props.name}>{props.children}</label>
      <input className="form-input" id={props.name} name={props.name} type={props.type} required value={props.fun || props.value} onChange={ props.funCall? (e) => props.funCall(e.target.value) : (e) => props.funHandle(e) }></input>
      </>
    )
  }

  export function Button (props:any){
    return(
        <button className={
            props.btnType === "btn-primary"?
            "btn-primary" :
            "btn-primary"
        } onClick={props.btnFun}>{props.children}</button>
    )
}