import React from "react"
import Auth from "../components/auth/Auth"
import SignupForm from "../components/auth/SignUpForm"

const SignUp = ()=>{
    return(
        <Auth Authform={SignupForm}/>
    )
}

export default SignUp;