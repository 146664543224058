import React from 'react'
import { useState } from "react";

const ResetPassword = () => {

  const [pass, set_pass] = useState("");
  const [cpass, set_cpass] = useState("");

  return (
    <div className="form_page">
      <div className="form_container">
        <form>
          <div className="form_header">
            <h2>Setup new password</h2>
            <p>Your new password must be different to previously used passwords</p>
          </div>
          <div className="form-control
">
          <label htmlFor="password">Password </label>
          <input
            className="form_input"
            type="password"
            name="password"
            id="password"
            placeholder="Enter new password"
            value={pass}
            onChange={(e) => set_pass(e.target.value)}
          />
          <span>Must be atleast 8 characters</span>
          </div>
          <div className="form-control
">
          <label htmlFor="confirm">Confrim Password </label>
          <input
            className="form_input"
            type="password"
            name="password"
            id="confirm"
            placeholder="Confirm password"
            value={cpass}
            onChange={(e) => set_cpass(e.target.value)}
          />
          </div>
          <input type="button" className="btn-primary" value="Submit"  />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
