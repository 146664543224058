import React from 'react';
import './Filter.scss';
import { FiChevronDown, FiChevronRight} from "react-icons/fi"
import { useReducer } from "react"
import { BiX } from "react-icons/bi"
import Categories from "../../assets/Categories";

export default function Filter({modalDisplay, setModalDisplay,}:any) {
    const [options, toggle] = useReducer(
        ((options) => !options),
        false
    )
    
    return (
        <div className={modalDisplay? "filter_modal" : "filter"}>
            <span onClick={()=>{setModalDisplay(false); document.body.removeAttribute("style")}} className="close-modal md"><BiX/></span>
            <div className="filter_container">
                <div className="filter_by_category">
                    <div className="select" id="filterCategory"> 
                    <button onClick={toggle}>Select category <span><FiChevronDown/></span></button> 
                        <ul className={options?"options options_display":"options options_hide"}>
                          {Categories && Categories.map((value,index) => {
                            return <li key={index}><input type="checkbox" value={value.name} /> {value.name}</li>
                          })}
                        </ul>
                    </div>
                </div>
                <div className="filter_by_price">
                    <h4 className="filter_header">Price range</h4>
                    <div className="price_range">
                    <input type="number" placeholder="Min" />
                    <span>-</span>
                    <input type="number" placeholder="Max" />
                    <button>
                        <FiChevronRight />
                    </button>
                    </div>
                    <div className="range">
                        <input type="checkbox" id="6000-7000">
                        </input><label htmlFor="6000-7000">6000TL-7000TL</label>
                        <div className="available">(21)</div>
                    </div>
                    <div className="range">
                        <input type="checkbox" id="3000-4000">
                        </input><label htmlFor="3000-4000">3000TL-4000TL</label>
                        <div className="available">(21)</div>
                    </div>
                </div>
                <div className="filter_by_location">
                    <h4 className="filter_header">Location</h4>
                    <div className="select">City <span><FiChevronDown/></span></div>
                    <div className="select">Town <span><FiChevronDown/></span></div>
                </div>
                <div className="filter_by_brand">
                <h4 className="filter_header_select">Brand <span><FiChevronDown/></span></h4>         
                </div>
            </div>
            </div>
    )
}
