import { useSelector } from "react-redux"
import { productCardFilters } from "../../services/Helpers"
import PastProduct from "../promo_cards/PastProduct"

export default function Watchlist({ type, page, userId }: any) {
    const products = useSelector((state: any) => state.feed) as any
    const Watchlist = useSelector((state: any) => state.shops.watchlists.watchlist) as any
    const Favourites = useSelector((state: any) => state?.shops?.favourites.favourites) as any

    console.log(Favourites, "check all stte")
    const allowed_actions = ["Add to Watchlist", "View Product", "Visit Store"]
    const tabType = type;
    let date = (new Date()).getTime()

    return (
        <div className="shop_products">
            { (tabType !== "EXPIRED" && page === "myProfile" )? products.products.filter((ele: any) => {
            
              return  ele.userId === userId 
            }).sort((a: any, b: any) => {

                return (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)
            }).map((product: any, index: any) => {
                if (tabType === "WATCHLIST" && Watchlist.includes(product._id)) {
                    return productCardFilters(product.type, product, allowed_actions, index)

                }
                else if (tabType === "FAVOURITES" && Favourites.includes(product._id)) {
                    return productCardFilters(product.type, product, allowed_actions, index)

                }
                else if (tabType === "SELLS" && product.status === "onSell") {
                    return productCardFilters(product.type, product, allowed_actions, index)

                }
                else if (tabType === "SOLD" && product.status === "sold") {
                    return productCardFilters(product.type, product, allowed_actions, index)

                }
                else if (tabType === "EXPIRED") {
                    return productCardFilters(product.type, product, allowed_actions, index)

                }
                else return '';
            }) : tabType === "EXPIRED" && page === "myProfile" && products.products.filter((ele: any) =>{
            
            let start = (new Date(ele['start'])).getTime()
            let end = (new Date(ele['end'])).getTime()
            return  ele.userId === userId && (end < date && start < date)}
            ).sort((a: any, b: any) => {

                return (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)
            }).map((product: any, index: any) => {
               
              
                    return<PastProduct product={product}  key={index}/> 

            })} 
            
        </div>
    )
}