import { create_shop } from "../../services";
import { useSelector, useDispatch} from "react-redux"
import { useNavigate } from 'react-router-dom';

import { useState } from "react";
import { toggle_loader } from "../../redux/actions/general";
import httpClient from "../../services/ApiCallHandler";

export default function CreateShop({page}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const profile = useSelector(state => state.profile)
    const [email, set_email] = useState('')
    const [shop_name, set_shop_name] = useState('')
    const [phone, set_phone] = useState('')
    const [country, set_country] = useState('')
    const [city, set_city] = useState('')
    const [street, set_street] = useState('')


    const create_store = () => {
        dispatch(toggle_loader(true))
        var data = JSON.stringify({
            "name": shop_name,
            "title": "Null",
            "location": "Null",
            "description": "Null",
            "currency": "Null",
            "userId": profile['id'],
            "category": "Null",
            "contact": phone,
            "phone": phone,
            "socialLinks": [],
            "images": ['https://blog.bindy.com/wp-content/uploads/2020/08/shutterstock_228389290-1024x683.jpg'],
            "registrationNumber": 0,
            "rating": 0,
            "isActive": true,
            "email": email,
            "address": street,
            "city": city,
            "state": country,
            "postalCode": "Null"
          });
          
        let config = create_shop(data)
        httpClient(config)
            .then(function (response) {
                dispatch(toggle_loader(false))
                navigate("profile")
                alert("Shop Created")
            })
            .catch(function (error) {
                console.log(error);
                dispatch(toggle_loader(false))
                alert("Shop Not Created")
            });
    }
    return (
        <div className="profile_main">
            <div className="edit_shop">
                <div>
                    <p className='auth_lable'>Shop Image</p>
                    <input type="file" accept="image/*" className='auth_input' />
                </div>
                <div>
                    <p className='auth_lable'>Shop Name</p>
                    <input type="text" className='auth_input' value={shop_name} onChange={(e)=>set_shop_name(e.target.value)}/>
                </div>
                <div>
                    <p className='auth_lable'>Street Address</p>
                    <input type="text" className='auth_input' value={street} onChange={(e)=>set_street(e.target.value)} />
                </div>
                <div>
                    <p className='auth_lable'>City</p>
                    <input type="text" className='auth_input' value={city} onChange={(e)=>set_city(e.target.value)}/>
                </div>
                <div>
                    <p className='auth_lable'>Country/State</p>
                    <input type="text" className='auth_input' value={country} onChange={(e)=>set_country(e.target.value)}/>
                </div>
                <div>
                    <p className='auth_lable'>Phone</p>
                    <input type="text" className='auth_input' value={phone} onChange={(e)=>set_phone(e.target.value)}/>
                </div>
                <div>
                    <p className='auth_lable'>Email</p>
                    <input type="text" className='auth_input' value={email} onChange={(e)=>set_email(e.target.value)}/>
                </div>
                
                
            </div>
            <button className="orange_btn" onClick={create_store}>Create Shop</button>
        </div>
    )
}