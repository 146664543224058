import React from 'react';
import { create_post } from "../../services";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { toggle_loader } from '../../redux/actions/general';
import Categories from "../../assets/Categories";
import Currencies from "../../assets/Currencies";
import { FiUpload } from "react-icons/fi"
import { AiFillCheckSquare, AiFillDelete, AiOutlineCheckSquare } from "react-icons/ai"

export default function Post({ shop_id }) {
    const dispatch = useDispatch()
    const profile = useSelector(state => state.profile)
    const [product_name, set_product_name] = useState("")
    const [desc, set_desc] = useState("")
    const [old_price, set_old_price] = useState("")
    const [new_price, set_new_price] = useState("")
    const [category, set_category] = useState("0")
    const [currency, set_currency] = useState("USD")
    const [start, set_start] = useState("")
    const [end, set_end] = useState("")
    const [promo_desc, set_promo_desc] = useState("")
    const [uploaded_images, set_uploaded_images] = useState([])
    const [main_image, set_main_image] = useState(-1)

    const create = () => {
        dispatch(toggle_loader(true))
        let start_data = (new Date(start)).getTime()
        let end_data = (new Date(end)).getTime()

        let formData = new FormData()
        formData.append("name", product_name)
        formData.append("title", desc)
        formData.append("description", promo_desc)
        formData.append("oldPrice", old_price)
        formData.append("newPrice", new_price)
        formData.append("currency", currency)
        formData.append("storeId", shop_id)
        formData.append("userId", profile['id'])
        formData.append("category", category)
        formData.append("start", start_data)
        formData.append("end", end_data)
        uploaded_images.forEach(element => {
            formData.append("images", element['file'])
        })
        var config = create_post(formData)
        axios(config).then(function (response) {
            dispatch(toggle_loader(false))
            if (response.statusText === "Created") {
                reset_values()
            } else {
                alert("not created")
            }
        })
            .catch(function (error) {
                dispatch(toggle_loader(false))
                console.log(error);
            });
    }
    const reset_values = () => {
        set_product_name("")
        set_desc("")
        set_old_price("")
        set_new_price("")
        set_category("0")
        set_currency("USD")
        set_start("")
        set_end("")
        set_promo_desc("")
        set_uploaded_images([])
    }

    const upload_image = (image) => {
        let obj = { file:image,src:""}
        const reader = new FileReader();
        reader.onloadend = () => {
            obj['src'] = reader.result
            set_uploaded_images(uploaded_images => [...uploaded_images, obj]);
        }
        reader.readAsDataURL(image);
    }
    const remove_image = (position) =>{
        let images = []
        uploaded_images.forEach((element, index) => {
            if(position !== index) images.push(element)
        });
        set_uploaded_images(images);
        set_uploaded_images(images);
    }
    return (
        <div className="profile_main">
            <div className="edit_shop">
                <div>
                    <p className='auth_lable'>Product Name</p>
                    <input type="text" className='auth_input' value={product_name} onChange={(e) => set_product_name(e.target.value)} />
                </div>
                <div>
                    <p className='auth_lable'>Product Description</p>
                    <input type="text" className='auth_input' value={desc} onChange={(e) => set_desc(e.target.value)} />
                </div>
                <div>
                    <p className='auth_lable'>Category</p>
                    <select className='auth_input' value={category} onChange={(e) => set_category(e.target.value)}>
                        {Categories.map((element, index) => {
                            return <option key={index} value={element.code}>{element.name}</option>
                        })}
                    </select>
                </div>
                <div>
                    <p className='auth_lable'>Currency</p>
                    <select className='auth_input' value={currency} onChange={(e) => set_currency(e.target.value)}>
                        {Currencies.map((element, index) => {
                            return <option key={index} value={index}>{element}</option>
                        })}
                    </select>
                </div>
                <div>
                    <p className='auth_lable'>Original Price</p>
                    <input type="text" className='auth_input' value={old_price} onChange={(e) => set_old_price(e.target.value)} />
                </div>
                <div>
                    <p className='auth_lable'>Promo Price</p>
                    <input type="text" className='auth_input' value={new_price} onChange={(e) => set_new_price(e.target.value)} />
                </div>

                <div>
                    <p className='auth_lable'>Start Date</p>
                    <input type="datetime-local" className='auth_input' value={start} onChange={(e) => set_start(e.target.value)} />
                </div>
                <div>
                    <p className='auth_lable'>End Date</p>
                    <input type="datetime-local" className='auth_input' value={end} onChange={(e) => set_end(e.target.value)} />
                </div>
                <div>
                    <p className='auth_lable'>Promotion Description</p>
                    <textarea type="text" placeholder="Why are you promoting this Product?" className='auth_input' rows="5" value={promo_desc} onChange={(e) => set_promo_desc(e.target.value)}></textarea>
                </div>
                <div>
                    <p className='auth_lable'>Add Images</p>
                    <div style={{ display: 'flex', flexWrap:'wrap' }}>
                        <div>
                            <label htmlFor="add_image" className="img_upload_btn">
                                <FiUpload />
                                <span>Upload</span>
                            </label>
                            <input type="file" accept="image/*" id="add_image" className='auth_input' hidden onChange={(e) => upload_image(e.target.files[0])} />
                        </div>
                        {uploaded_images.map((element, index) => {
                            return <div key={index} className="upload_image-containers">
                                <img src={element.src} alt="uploaded" />
                                <div className="uploaded_images_bot">
                                    <button onClick={()=>set_main_image(index)}>
                                        {index === main_image ? <AiFillCheckSquare color="#1B9CEA" /> : <AiOutlineCheckSquare color="#999"/>}
                                    </button>
                                    <button className="delete_image" onClick={()=>remove_image(index)}><AiFillDelete /></button>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <button className="orange_btn" onClick={create}>Post Product</button>
        </div>
    )
}
