import ShopRow from '../shop/ShopRow';
import { useDispatch, useSelector} from "react-redux"
import { useEffect, useState } from 'react';
import * as SERVICE from '../../services';
import { get_all_shops } from '../../redux/actions/shop';
import { toggle_loader } from '../../redux/actions/general';
import httpClient from '../../services/ApiCallHandler';

export default function MyShops({page}) {
    const dispatch = useDispatch()
    const profile = useSelector(state => state.profile);
    const old_shops = useSelector(state => state.shops)
    const [shops, set_shops] = useState(old_shops.shops)

    useEffect(()=>{
      
        if(profile.id){
            // if(shops.length < 1){
            //     dispatch(toggle_loader(true))
            // }
            let config = SERVICE.getUserShops(profile.id)
            httpClient(config).then(function (response) {
                let shops = response['data']['Shops'].reverse()
                dispatch(get_all_shops(shops))
                set_shops(shops)
                dispatch(toggle_loader(false))
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        
    },[dispatch, profile.id, shops.length])
    return (
        <div className="my_shops">
            {shops.length > 0 ? shops.map((shop, index) => {  return <ShopRow shop={shop} key={index}/>}) :  <> no active shops</>}       
        </div>
    )
}