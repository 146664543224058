import React from 'react';
import "../css/ViewProduct.scss";
import Footer from "../components/general/Footer";
import Navigation from "../components/navigation/Navigation";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import SidePromos from "../components/view/SidePromos";
import { useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { view_product } from '../services';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Categories from '../assets/Categories'
import Product from '../components/promo_cards/Product';
import { MoneyFormatter } from '../services/Helpers';
import {AiFillCaretRight} from "react-icons/ai"
import Promotion from '../components/viewproduct/Promotion';
import { toggle_loader } from '../redux/actions/general';

export default function ViewProduct() {
    const dispatch = useDispatch()
    let { id } = useParams();
    const [product, set_product] = useState({
        name: "",
        title: "",
        description: "",
        shopName: "",
        currency: "",
        newPrice: "",
        oldPrice: "",
        percentage: "",
        category: "",
        end: "",
        images: [],
        location: "Plot 91 President Ave, Ndola 50100, Zambia"
    })
    const [related, set_related] = useState([])
    const [other_products, set_other_products] = useState([])
    let start_data = new Date()
    let end_data = new Date(product.end)
    let ends_seconds = (end_data.getTime() - start_data.getTime()) / 1000
    const [end_time, set_end_time] = useState(ends_seconds)
    const [image, set_image] = useState(0)

    const messageSeller = (recepientId: string, ProductId: any) => {
        if(recepientId){
            let productRef = (ProductId)? `?product=${ProductId}` : '';
            window.location.href = window.location.origin+`/messages/${recepientId + productRef}`;
            // window.open(`messages/${recepientId + productRef}`,`_self`)
        }
        
    }

    const map_location = () => {
        window.open(`http://maps.google.com/?q=${product.location}`)
    }
    const switch_image = (type: number) => {
        let max = product.images.length - 1
        let current = image
        current += type
        if (current > max) current = 0
        if (current < 0) current = max
        set_image(current)
    }
    useEffect(() => {
        let config = view_product(id)
        dispatch(toggle_loader(true))
        axios(config).then(function (response) {
            let product = response['data']['product'][0].singleProduct
            let percentage = 100 - Math.floor((+product.newPrice / +product.oldPrice) * 100) || 0
            product['percentage'] = percentage
            product['location'] = product['address'] + " " + product['city'] + " " + product['state']
            let end_date = new Date(product.end)
            let ends_seconds = (end_date.getTime() - (new Date()).getTime()) / 1000
            set_end_time(ends_seconds)
            let pic_keys: any[] = []
            product['images'].forEach((element: { [x: string]: any; }) => {
                pic_keys.push(element['Location'])
            });
            product['images'] = pic_keys
            set_product(product)

        let related = response["data"]["product"][2]["relatedProducts"];
        set_related(related);
        set_other_products(related)
        dispatch(toggle_loader(false));
      })
      .catch(function (error) {
        dispatch(toggle_loader(false));
        console.log(error);
      });
  }, [id, dispatch]);

  useEffect(() => {
    let timer = setInterval(() => {
      set_end_time(ends_seconds - 60);
    }, 60000);
    return () => clearInterval(timer);
  }, [ends_seconds]);

  const formatter = (time: number) => {
    let units = " min";
    if (time > 86400) {
      time = Math.ceil(time / 60);
      time = Math.ceil(time / 60);
      time = Math.ceil(time / 24);
      units = " days";
    } else if (time > 3600) {
      time = Math.ceil(time / 60);
      time = Math.ceil(time / 60);
      units = " hours";
    } else {
      time = Math.ceil(time / 60);
      units = " minutes";
    }

    if (time > 0) return "Promo ends in " + time + units;
    else return "Ended on " + end_data.toDateString();
  };

  function GetCategory({ id }:any) {
    let name = "Other";
    Categories.forEach((element) => {
      if (+id === +element["code"]) name = element["name"];
    });
    return name;
  }

  const allowed_actions = ["Add to Watchlist", "View Product", "Visit Store"];

  const [bottomTab, setBottomTab] = useState("promotion_selected");
  const Location = () => {
    return (
      <div className="location">
        <div className="location_map"></div>
      </div>
    );
  };



  

    return (
        <div className="">
            <Navigation />
            <div className="view_product_body">
            <div className="path">Home  {'>'}  Electronics  {'>'}  Computers  {'>'}  Laptops  {'>'}  Huawei Laptops  {'>'}  Huawei MateBook D15 Intel</div>
                <div className='view_product_container'>
                    <div className='image_side'>
                        <div className='view_product_image'>
                            <button onClick={() => switch_image(-1)}><AiFillLeftCircle /> </button>
                            <img crossOrigin="anonymous" src={product.images[image]} alt={product.name} onClick={() => window.open(product.images[image])} />
                            <button onClick={() => switch_image(1)}><AiFillRightCircle /></button>
                        </div>
                        <div className='image_slide'>
                            {product.images.map((element, index) => {
                                return <img key={index} crossOrigin="anonymous" onClick={() => set_image(index)} src={element} style={index === image ? { border: '3px solid orange' } : {}} alt="img" />
                            })}
                        </div>
                    </div>
                    <div className='view_product_details'>
                        <div className="product_detail">
                        <span className='view_product_name'><h5>{product.name}</h5></span>
                        <span className='view_product_title'><h3>{product.title}</h3></span>
                        <p className='view_product_desc'></p>
                        <p className='view_product_cat'>{GetCategory(product.category)}</p>
                        <Link to={"/Store/" + product['shopName']}>
                            <p className='view_product_store'>By {product.shopName}</p>
                        </Link>
                        <p className='view_product_shop'>{product.location}</p>
                        <div className='view_product_mid'>
                            <div className='ribbon_side'>
                                <div className='ribbon'>
                                    <p>{product.percentage}%</p>
                                    <p>Off</p>
                                </div>
                                <div>
                                    <p className='view_old_price'>{MoneyFormatter(product.oldPrice,product.currency)}</p>
                                    <p className='view_new_price'>{MoneyFormatter(product.newPrice,product.currency)} </p>
                                </div>
                            </div>
                            <div className='reserve'>
                                <p>{formatter(end_time)}</p>
                                <button onClick={map_location}>Map Location</button>
                            </div>
                        </div>
                        <p className='view_product_about'>{product.description}</p>
                        <div className="view_products_buttons">
                            <button>More Details</button>
                            <button onClick={() => messageSeller(product.shopName, product.name)}>Message Dealers</button>
                        </div>
                        </div>
                        <div className="other_products_section">
                            <div className="other_products_nav">
                                <button onClick={()=>setBottomTab("promotion_selected")} className={bottomTab === "promotion_selected"?"tab_active":""}>Promotions</button>
                                <button onClick={()=>setBottomTab("location_selected")} className={bottomTab === "location_selected"?"tab_active":""}>Location</button>
                            </div>
                            <div className="other_products_body">
                                {
                                    bottomTab === "location_selected"?(
                                        <Location/>): (<Promotion product={product}/>)
                                }

                            </div>
                        </div>
                    </div>
                    <div className="side_promotions">
                        <div className='side_promo_top'>
                            <p className='promo_name'>Latest Adds</p>
                            <Link to={"/Store/" + product['shopName']}>
                            <button className='side_promo_top_btn'>
                                View all &nbsp; <AiFillCaretRight/>
                            </button>
                            </Link>
                        </div>
                        {other_products.map((product, index) => {
                            return <SidePromos key={index} product={product} />
                        })}
                    </div>
                </div>
                <div className="view_product_bottom">
                    <div className="location"></div>
                        <div className="container">
                        <div className="related_items">
                        <h3>Related items</h3>
                        <div className='related_items_list'>
                            {related.map((product, index) => {
                                return <Product allowed_actions={allowed_actions} product={product} key={index} />
                            })}
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}
