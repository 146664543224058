import './css/App.scss';
import ViewProduct from './pages/ViewProduct';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Profile from './pages/Profile';
import Shop from './pages/Shop';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggle_loader } from './redux/actions/general';
import * as SERVICE from "./services"
import axios from 'axios';
import Session from './services/Session';
import { load } from './redux/actions/profile';
import VisitShop from './pages/VisitShop';
import Search from './pages/Search';
import Home from './pages/Home';
import  Message from './pages/Messages';

import Account from './components/profile/Account';
import ChangePassword from './components/profile/ChangePassword';
import MyShop from './components/profile/MyShop';
import Error from './pages/Error';
import Settings from './components/profile/Settings';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import React from 'react';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import { userDataObjectFormatter } from './services/Helpers';
import { useJsApiLoader } from '@react-google-maps/api';


function App() {
  const dispatch = useDispatch()
  const connectGogle = useJsApiLoader;
  try {
    const { isLoaded } = connectGogle({
        libraries:['places'],
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || ""
      })
      if(!isLoaded){
        console.log('google maps not loaded')
      }
  } catch (error) {
    console.log(error)
  }
  
  useEffect(() => {
    dispatch(toggle_loader(true))
    let config = SERVICE.auto_login() as any
    axios(config).then(function (response) {
      if (response['data'].length) {
        let data = response['data'][0]
        let session = {
          accessToken: data['accessToken'],
          refreshToken: data['refreshToken']
        }
        Session(session, "POST")
        const loginData = userDataObjectFormatter(data['userData'][0]);
        dispatch(load(loginData))
      }
      dispatch(toggle_loader(false))
    })
      .catch(function (error) {
        dispatch(toggle_loader(false))
        console.log(error);
      });
  }, [dispatch])


  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Product/:id" element={<ViewProduct />} />
          <Route path="/profile/" element={<Profile />}>
            <Route path="settings" element={<Settings />} />
            <Route path="account" element={<Account />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="myshop" element={<MyShop />} />
          </Route>
          <Route path="/Shop/:id/:page/:product_id" element={<Shop />} />
          <Route path="/Store/:id" element={<VisitShop />} />
          <Route path="*" element={<Error />} />
          <Route path="/search" element={<Search />} />
          <Route path="/messages/:recepientId" element={<Message />} />
          <Route path="/messages" element={<Message />} />
          <Route path="/verify" element={<ForgotPassword />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
      
    </>

  );
}

export default App;
