import React, { useEffect } from 'react';
import { useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux"

import chat from '../assets/chat.png';

import "../components/profile/Messages.scss";
import Navigation from "../components/navigation/Navigation";
import httpClient from '../services/ApiCallHandler';
import * as SERVICE from "../services";
import { useParams  } from 'react-router-dom';
import { io } from "socket.io-client";
import { sortChats } from '../services/Helpers';
import { ImAttachment } from 'react-icons/im';
import { AiOutlineSend } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';


const Messages = () => {
    const { recepientId } = useParams();
    // const params = new URLSearchParams(useLocation().search);
    const profile = useSelector((state: any) => state.profile)
    const bottomRef = useRef(null) as any;
    const [chatlistApi, setChatListApi] = useState([]) as any;
    const [formData, setFormData] = useState({
        name: "",
        title: "",
        message: "",
        reaction: "",
        deleted: false,
        socialLinks: [],
        images: [],
        files: [],
        userId: "",
        fromUserId: "",
        toUserId: "",
        seen: false,
        mentionedProduct: "",
        mentionedShop: ""
    })
    const [chatConversation, setChatConversation] = useState([]) as any;
    const [openChat, setOpenChat] = useState({}) as any
    const [displayChat, setdisplayChat] = useState(false);
    const [arrivalMessage, setArrivalMessage] = useState(null) as any;
    // const [onlineUsers, setOnlineUsers] = useState([]);
    const socket = useRef() as any;
     
  
    useEffect(() => {
        socket.current = io(process.env.REACT_APP_SOCKET_URL as any);
        socket.current.emit("addUser", profile.id);
        socket.current.on("getUsers", (users:any) => {
            // setOnlineUsers(users);
        });
    }, [ profile.id]);
 
    useEffect(() => {
      const onFetchData =  socket.current.on("getMessage", (data: any) => {
            setArrivalMessage( {...data,
                createdAt: new Date(),
              })

             
        });
         return () => {
            onFetchData.off()
         }
      });
    

    useEffect(() => {
         arrivalMessage && (openChat.id === arrivalMessage.toUserId || openChat.id === arrivalMessage.fromUserId) && setChatConversation((prev:any) => [...prev,arrivalMessage]);      
        
        return (() => {
            
        })
    }, [arrivalMessage, chatlistApi, openChat.id, profile.id]);


    useEffect(() => {
        if(arrivalMessage){
            let newObj = chatlistApi;
            let checkID = (profile.id === arrivalMessage.userId)? arrivalMessage.toUserId : arrivalMessage.fromUserId
            console.log(chatlistApi.findIndex((x:{id:string}) => x.id === checkID), "chec index", checkID)
            const indexToUpdate =  chatlistApi.findIndex((x:{id:string}) => x.id === checkID);
            if(newObj[indexToUpdate]){
                newObj[indexToUpdate].message = arrivalMessage.message;
                newObj[indexToUpdate].time = arrivalMessage.createdAt;
            }
           
            console.log(newObj,"burata")
            setArrivalMessage(newObj)
            
        }
        
    }, [arrivalMessage, chatlistApi, profile.id])
            
    
    
  
    const newUserMessageLogic = useCallback(() => {
        if(recepientId){
            setOpenChat({ id:recepientId, incoming: false, name: "test", message: [], time:"", seen: false })
        }
    },[recepientId]);

    
    const restData = () => {
        setFormData({
            name: "",
            title: "",
            message: "",
            reaction: "",
            deleted: false,
            socialLinks: [],
            images: [],
            files: [],
            userId: "",
            fromUserId: "",
            toUserId: "",
            seen: false,
            mentionedProduct: "",
            mentionedShop: ""
        })
    }
    const updateSendObject = (e: any) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        });
     
    }
    const handleSendMessage = (e: any) => {
        e.preventDefault();
        if (profile.id && openChat.id && formData.message) {
            let sendObj = {
                name: profile.firstName + ' ' + profile.lastName,
                title: "",
                message: formData.message,
                reaction: "like",
                deleted: false,
                socialLinks: [],
                images: [],
                files: [],
                userId: profile.id,
                fromUserId: profile.id,
                toUserId: openChat.id,
                seen: false,
                mentionedProduct: "62d091b1b242e4378210fb65",
                mentionedShop: "62d091b1b242e4378210fb65"
            }
            
            socket.current.emit("sendMessage", sendObj);

            const config = SERVICE.sendMessage(sendObj)
            httpClient(config).then(function (response) {

                setChatConversation(response.data.chats)
                fetchMessages()
                restData()
                bottomRef.current?.scrollIntoView({ behavior: 'smooth' });

            }).catch(function (error) {
                console.log(error)
            })
        }


    }
    const ChatMessage = ({ message }: any) => {
        const currentUser = profile.id;
        return (
            <>
                <div className="messages_body">
                {
                    message.map((message: any, index: any) => {
                        return (
                            <div className={message.fromUserId === currentUser ? 'message_sent' : 'message_received'} key={index}>
                                <div className="message">
                                    <p className="text">{message.message}</p>
                                    <div className="date">{timeAgo(message.createdAt)}</div>
                                </div>
                            </div>
                        )

                    })
                }
                    <div ref={bottomRef} />
                </div>
              
            </>

        )
    }
    const fetchMessages = useCallback(() => {
        const config = SERVICE.fetchUserMessages(profile.id)
        if (profile.id) {
            httpClient(config).then(function (response) {
                setChatListApi(sortChats(response.data.chats, profile.id))
            }).catch(function (error) {
                console.log(error)
            })
        }
       

    }, [profile.id]);

   

    const timeAgo = (time: any) => {
        let date = new Date(time);
        return date.getHours() + " :" + date.getMinutes()
    }
    const fetchConversation = (id: any, myPfrofileId: any, chat: any) => {
        const config = SERVICE.fetchUseConversation(id, myPfrofileId)
        httpClient(config).then(function (response) {
            // sortChats(response.data.chats,profile.id)
            setChatConversation(response.data.chats)
            setOpenChat(chat);
            setdisplayChat(true);

        }).catch(function (error) {
            console.log(error)
        })

    }
   

    const filterList = (e: any) => {
        const searchWord = e.target.value;
        const newFilter = chatlistApi.filter((value: any) => {
            return value.name.toLowerCase().includes(searchWord.toLowerCase());
        });
        if (searchWord === "") {
            fetchMessages();
        } else {
            setChatListApi(newFilter);
        }

    }
   
    useEffect(() => {
        fetchMessages();
        newUserMessageLogic()

    }, [fetchMessages,newUserMessageLogic])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatConversation]);

    return (
        <>
          <Navigation />
            <div className="chat">
                <div className="chat_container">
                    <div className={displayChat? "chat_list_wrapper hide_chat_list":"chat_list_wrapper"}>
                        <div className="chat_list_search">
                            <input type="search" className="search-bar" onChange={(e) => filterList(e)} placeholder="Search" />
                        </div>
                        <div className="chat_list">

                            {chatlistApi.length !== 0 &&
                                chatlistApi.sort((b: { time: any | number | Date; },a: { time: any | number | Date; }) =>  {
                                         let sortb = new Date(b.time) as any ;
                                         let sorta = new Date(a.time) as any ;
                                         return   sorta - sortb 
         
                                        }).map((chat: any) => {
                                    return (
                                        <div key={chat.time} id={chat.id} onClick={() => { fetchConversation(chat.id, profile.id, chat); }} className={openChat === chat.id ? " chat_list_item chat_list_item_active" : "chat_list_item"}>
                                            <div className="details">
                                                <h5 >{chat.name}</h5>
                                                <p key={chat.id} style={{fontWeight:(!chat.seen && chat.incoming)? "500" : "200"}}>{chat.message}</p>
                                            </div>
                                            <div className="date">
                                                <span style={{fontWeight:(!chat.seen && chat.incoming)? "1000" : "200"}}>{timeAgo(chat.time)}</span>
                                            </div>
                                        </div>

                                    )
                                })

                            }


                        </div>

                    </div>
                    {
                        Object.keys(openChat).length === 0 ?
                            <div className="chat_placeholder">
                                <div className="placeholder_image">
                                    <img src={chat} alt="chat" />
                                </div>
                            </div>
                            :
                            <div className={displayChat? "chat_body_wrapper display_chat": "chat_body_wrapper" }>
                                <div className="chat_header">
                                    <div className="header_details">
                                    <button onClick={() => setdisplayChat(false)} className="md"><BiArrowBack/></button>
                                        <div className="name">{openChat.name}</div>
                                        <div className="description">{openChat.title}</div>
                                    </div>
                                </div>
                                <div className="chat_body">
                                    <ChatMessage key="id" message={chatConversation}  />

                                </div>
                                <form className="chat_form" onSubmit={(e: any) => handleSendMessage(e)}>
                                    <label htmlFor="attach">
                                    <ImAttachment/>
                                    <input type="file" name='attach' id='attach'/>
                                    </label>

                                    <div className="form_message">
                                    <input name="message" type="text" id="" value={formData.message} onChange={(e: any) => updateSendObject(e)} placeholder="Write a message...." autoComplete='off' />
                                    <button type="submit"><AiOutlineSend/></button>             
                                    </div>
                                </form>
                            </div>
                    }

                </div>
            </div>
        </>
    )
}

export default Messages
