import React from 'react';
import axios from "axios"
import { useDispatch } from "react-redux"
import { toggle_loader } from "../../redux/actions/general"
import { useNavigate } from "react-router-dom";
import * as SERVICE from "../../services"
export default function EditShop({shop_id}) {
    const dispatch = useDispatch()
    let navigate = useNavigate();

    const delete_shop = () => {
        let config = SERVICE.delete_shop(shop_id)
        dispatch(toggle_loader(true))

        axios(config).then(function (response) {
            if(response['data']['delete']){
                navigate('/Profile');
            }
            dispatch(toggle_loader(false))
        })
        .catch(function (error) {
            dispatch(toggle_loader(false))
            console.log(error);
        });
    }
    return (
        <div className="profile_main">
            <div className="edit_shop">
                <div>
                    <p className='auth_lable'>Change Picture</p>
                    <input type="file" accept="image/*" className='auth_input' />
                </div>
                <div>
                    <p className='auth_lable'>Shop Name</p>
                    <input type="text" className='auth_input' />
                </div>
                <div>
                    <p className='auth_lable'>Email</p>
                    <input type="text" className='auth_input' />
                </div>
                <div>
                    <p className='auth_lable'>Phone</p>
                    <input type="text" className='auth_input' />
                </div>
                <div>
                    <p className='auth_lable'>Country</p>
                    <input type="text" className='auth_input' />
                </div>
                <div>
                    <p className='auth_lable'>City</p>
                    <input type="text" className='auth_input' />
                </div>
                <div>
                    <p className='auth_lable'>Street Address</p>
                    <input type="text" className='auth_input' />
                </div>
            </div>
            <div>
            <button className="orange_btn">Save Changes</button>&nbsp;&nbsp;
            <button className="delete_shop_btn" onClick={delete_shop}>Delete Shop</button>
            </div>
        </div>
    )
}
