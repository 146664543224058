import { MODAL_OPEN } from "../actions/general";


const defaultState =  {
    open:false
};

export const post_modal = (state = defaultState, action) => {

    const{type,payload} = action;
    switch (type) {
      case MODAL_OPEN:{
        state.open = payload
        return {...state}
      }
      default: return state
    }
  }