import React from "react";
import './SearchBar.scss';


const SearchBar = ({handleKeyDown, openSearch, setOpenSearch, handleFilter, MdClose, MdSearch, filterProducts, focusedIndex, resultContainer, set_search_value,search_value, location}:any) =>{

    const mobile = window.matchMedia("(max-width: 768px)");
    const goToProduct = (data:any) =>{
      return window.location.href = `Product/${data._id}`;
    }
    return(
        <form  tabIndex={1} onKeyDown={handleKeyDown} className={ openSearch === true? "search": "search search-md-hidden"} >
        <div className={openSearch?"search-bar-overlay":"search-bar-container"}>
        <span
          className="close-modal"
          style={openSearch ? { display: "block" } : { display: "none" }}
          onClick={() => {
            setOpenSearch(false);
            document.body.removeAttribute('style')
            set_search_value("");
            filterProducts.length = 0;

          }}
        >
          <MdClose />
        </span>
        <div className="search-bar">
        <button className="search-btn">
            <MdSearch />
          </button>
          <input
            type="search"
            placeholder="Search..."
            onChange={ handleFilter}
            className="search-input"
            onFocus={()=>{

                if(mobile.matches){
                    setOpenSearch(true);
                    document.body.setAttribute('style',"height:100vh; overflow:hidden;")}}
                }
            value={search_value}
          />

        </div>
        {filterProducts.length !== 0 && (
          <div className="search-data">
            {filterProducts.slice(0, 10).map((value:any, index:any) => {
              return (
                <span
                  className="result"
                  key={index}
                  ref={index === focusedIndex ? resultContainer : null}
                  style={{
                    backgroundColor:
                      index === focusedIndex ? "#F5F8FF" : "",
                  }}
                onClick={()=>goToProduct(value)}
               >
                  <p>{value.name}</p>
                </span>
              );
            })}
          </div>
        )}

        </div>

      </form>
    )
} 

export default SearchBar;