import React from "react";
import './NotificationMenu.scss';



const NotificationMenu = ()=>{
    const notifications = []
    return(
        <>
        <div className="notifications_menu">
            <div className="notifications_container">
                <div className="menu_header">
                    <h4>Notifications</h4>
                </div>
                <div className="menu_content">
                    {notifications.length < -1 ?
                                        <div className="notifications_wrap">
                                        <div className="notification">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        </div>
                                        <div className="notification">
                                        Lorem ipsum dolor sit amet, consectetur  elit,
                                        </div>
                                        <div className="notification">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        </div>
                                        <div className="notification">
                                        Lorem ipsum dolor sit amet, c adipiscing elit,
                                        </div>
                                    </div> 
                    :                    
                    <div className="notifications_placeholder">
                    <span>empty </span>
                </div>
                }

                </div>
            </div>
        </div>
        </>
    )

}

export default NotificationMenu;