import React from 'react';
import Styles from './EditPost.module.scss';
import { edit_post, view_product ,getUserShops} from "../../services";
import axios from "axios";
import {  useDispatch,useSelector} from "react-redux";
import { useEffect, useState } from "react";
import { toggle_loader } from '../../redux/actions/general';
import Categories from "../../assets/Categories";
import Currencies from "../../assets/Currencies";
import { useParams } from "react-router-dom";
import moment from "moment";
import httpClient from '../../services/ApiCallHandler';

import { FiUpload } from "react-icons/fi"
import { AiFillCheckSquare, AiFillDelete, AiOutlineCheckSquare } from "react-icons/ai"
import styles from '../../components/general/CreateAdModal.module.scss';
import { Autocomplete } from '@react-google-maps/api';

import { POST_TYPES } from "../../services/Helpers";

export default function EditPost({shop_id}:any) {
    // let navigate = useNavigate();
    let dispatch = useDispatch();
    let { product_id } = useParams<{product_id?:string}>()  as any;

     const profile = useSelector((state:any) => state.profile)
    const [productId, setProductId] = useState("")
    const [product_name, set_product_name] = useState("")
    const [desc, set_desc] = useState("")
    const [myShops, setMyShops] = useState([])  as Array<any>
    const [selectedShop, setSelectedShop] = useState("")
    const [postType, setPostType] = useState("advert")
    const [bidStartPrice,setBidStartPrice] = useState("0")
    const [old_price, set_old_price] = useState("")
    const [new_price, set_new_price] = useState("")
    const [category, set_category] = useState("0")
    const [currency, set_currency] = useState("USD")
    const [start, set_start] = useState("")
    const [end, set_end] = useState("")
    const [price,setPrice] = useState("0")
    const [ownerType, setOwnerType] = useState("private")
    const [location, setLocation] = useState("")
    const [userId, setUserId] = useState("")

    const [promo_desc, set_promo_desc] = useState("")
    const [uploaded_images, set_uploaded_images] = useState([]) as any;
    const [main_image, set_main_image] = useState(-1)
    const [imagesToDelete, set_imagesToDelete] = useState([]) as Array<any>

    useEffect(() => {
        let config = view_product(product_id)
        dispatch(toggle_loader(true))
        
        httpClient(config).then(function (response) {
            let product = response['data']['product'][0].singleProduct
            setProductId(product_id)
            setUserId(product['userId'])
            set_product_name(product['name'])
            set_desc(product['title'])
            setPostType(product['type'])
            setPrice(product['price'] || "0")
            set_new_price(product['newPrice'] || "0")
            setBidStartPrice(product['bidPrice'] || "0")
            set_old_price(product['oldPrice'] || "0")
            setSelectedShop(product['storeId'] || "")
            set_start(date_time_formate(product['start']))
            set_end(date_time_formate(product['end']))
            set_promo_desc(product['description'])
            setLocation(product['location'] || "")
            setOwnerType(product['ownerType'] || "")
            
            set_category(product['category'])
            set_currency(product['currency'])
            let images: any[] = []
            product['images'].forEach((element: any) => { images.push({file:"", image:element}) });
            set_uploaded_images(images);
            dispatch(toggle_loader(false))
        })
            .catch(function (error) {
                dispatch(toggle_loader(false))
                console.log(error);
            });


            let configData = getUserShops(profile.id)
            httpClient(configData).then(function (response) {
                let shops = response['data']['Shops'].reverse()             
                setMyShops(shops)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [dispatch, product_id, profile.id])

    const changes = () => {
        if(userId !== profile["id"]){
            return
        }

        dispatch(toggle_loader(true))
        let start_data = (new Date(start? start : new Date())).getTime() as any;
        let end_data = (new Date(end ? end : new Date())).getTime() as any;

        let formData = new FormData()
        formData.append("name", product_name)
        formData.append("title", desc)
        formData.append("description", promo_desc)
        formData.append("oldPrice", old_price)
        formData.append("newPrice", new_price)
        formData.append("price", price);  
        formData.append("currency", currency) 
        formData.append("productId", productId)
        formData.append("storeId",(ownerType === "private")? profile.id : selectedShop)
        formData.append("bidPrice", bidStartPrice)
        formData.append("userId", profile['id'])
        formData.append("type", postType)
        formData.append("category", category)
        formData.append("ownerType", ownerType)
        formData.append("location", location)
        formData.append("start", start_data)
        formData.append("end", end_data)
        let images_to_delete: any[] = []
        imagesToDelete.forEach((element: { [x: string]: { [x: string]: any; }; }) => { 
            images_to_delete.push(element['image']['key'])
        })
        formData.append("imagesToDelete", JSON.stringify(images_to_delete))
        uploaded_images.forEach((element:any) => { 
            if(element?.new){
                formData.append("updateImages", element['file'])
            }
        })

        var config = edit_post(formData)
        axios(config).then(function (response) {
            dispatch(toggle_loader(false))
            console.log(response)
            if (response.statusText === "Created") {
                reset_values()
            } else {
                alert("not created")
            }
        })
        .catch(function (error) {
            dispatch(toggle_loader(false))
            console.log(error);
        });



    }

    const reset_values = () => {
        set_product_name("")
        set_desc("")
        set_old_price("")
        setBidStartPrice("0")
        set_new_price("")
        set_category("0")
        set_currency("USD")
        set_start("")
        set_end("")
        setPostType("")
        setLocation("")
        set_promo_desc("")
        set_uploaded_images([])
        set_imagesToDelete([])
    }

    const date_time_formate = (time: moment.MomentInput) => {
        console.log(time)
        let date = moment(time).toDate()
        let year = date.getFullYear()
        let mon = (date.getMonth() + 1).toString().length > 1 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)
        let day = date.getDate().toString().length > 1 ? date.getDate() : "0" + date.getDate()
        let hour = date.toTimeString().substring(0,5)
        return `${year}-${mon}-${day}T${hour}`
    }

    const upload_image = (image: Blob) => {
        let obj = { file:image,image:{Location:""}} as any
        const reader = new FileReader();
        reader.onloadend = () => {
            obj['new'] = true
            obj['image']['Location'] = reader.result
            set_uploaded_images((uploaded_images: any) => [...uploaded_images, obj]);
        }
        reader.readAsDataURL(image);
    }
    const remove_image = (position: any) =>{
        let images: any[] = []
        uploaded_images.forEach((element: any, index: any) => {
            if(position !== index) images.push(element) 
            else set_imagesToDelete((imagesToDelete: any) => [...imagesToDelete, element])
        });
        set_uploaded_images(images);
        set_uploaded_images(images);
    }

    return (
        <div className={Styles.edit_post}>
            <div className={styles.modal_top}>
                    <h3>
                        Edit Advert
                    </h3>
             </div>
             <div className={styles.modal_container}>
                <div className={styles.form}>
                <div className={styles.form_control
}>
                                    <label htmlFor='auth_lable'>Post Type</label>
                                    <select id='auth_input' value={postType} onChange={(e) => setPostType(e.target.value)}>
                                        {POST_TYPES.map((element, index) => {
                                            return <option key={index} value={element.slug}>{element.value}</option>
                                        })}
                                    </select>
                                </div>

                                <div className={styles.form_control
}>
                                    <label htmlFor="product">Product Name</label>
                                    <input type="text" name="product" id='product' placeholder='Product Name' value={product_name} onChange={(e) => set_product_name(e.target.value)} />
                                </div>
                                <div className={styles.form_control
}>
                                    <label htmlFor="category">Category</label>
                                    <select name="category" id='category' value={category} onChange={(e) => set_category(e.target.value)}>
                                        {Categories.map((element, index) => {
                                            return <option key={index} value={element.code}>{element.name}</option>
                                        })}
                                    </select>
                                </div>

                                <div className={styles.form_control
}> 
                                <label htmlFor="postOwner">Post Owner</label>
                                  <div className={styles.check_radio} id="postOwner">
                                    <div className={styles.radio_wrapper} > 
                                       <label htmlFor="private">Private</label> 
                                       <input type="radio" id="private" checked={ownerType === "private"}  name="PostType" value="private"  onChange={(e) => setOwnerType(e.target.value)} />   

                                   </div>                          
                                   {myShops.length > 0 && (

                                    <div className={styles.radio_wrapper}> 
                                        <label htmlFor="firm">Firm</label>
                                        <input type="radio" id="firm" name="PostType" checked={ownerType === "firm"} disabled={myShops.length === 0} value="firm" onChange={(e) => setOwnerType(e.target.value)} />          
                                    </div>

                                   )}
                                    

                                  </div>     
                                </div>

                                {ownerType === "firm" && myShops.length > 0 &&  
                                    (
                                        <>
                                            <div className={styles.form_control
}>
                                                <label htmlFor="shop">Shop</label>
                                                <select name="shop" id='shop'  value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}>
                                                <option value="">choise from list</option>
                                                    {myShops.map((element:any, index:any) => {
                                                        return <option key={index} value={element._id}>{element.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </>
                                    )
                                }

                                    
                              { postType === "promotion"?
                                (  
                                    <>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='oldPrice' >Original Price</label>
                                            <input id='oldPrice' type="number" className='auth_input' value={old_price} onChange={(e) => set_old_price(e.target.value)} placeholder="00000"/>
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='newPrice' >Promo Price</label>
                                            <input  id='newPrice' type="number" className='auth_input' value={new_price} onChange={(e) => set_new_price(e.target.value)}  placeholder="00000" />
                                        </div>

                                        <div className={styles.form_control
}>
                                            <label  htmlFor='startDate'>Start Date</label>
                                            <input  id='startDate' type="datetime-local" className='auth_input' value={start} onChange={(e) => set_start(e.target.value)} />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='endate' >End Date</label>
                                            <input id="endate" type="datetime-local" className='auth_input' value={end} onChange={(e) => set_end(e.target.value)} />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='promotionDesc'>Promotion Description</label>
                                            <textarea  id='promotionDesc' placeholder="Why are you promoting this Product?" className='auth_input' rows={5} value={promo_desc} onChange={(e) => set_promo_desc(e.target.value)}></textarea>
                                        </div>
                                        </>
                                 ) :
                                 postType === "auction"? 

                                 (
                                    <>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='bitPrice' >Bid Start Price</label>
                                            <input  id='bitPrice' type="number" className='auth_input' value={bidStartPrice} onChange={(e) => setBidStartPrice(e.target.value)}  placeholder="00000" />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label  htmlFor='startDate'>Start Date</label>
                                            <input  id='startDate' type="datetime-local" className='auth_input' value={start} onChange={(e) => set_start(e.target.value)} />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='endate' >End Date</label>
                                            <input id="endate" type="datetime-local" className='auth_input' value={end} onChange={(e) => set_end(e.target.value)} />
                                        </div>
                                    </>
                                 ) 
                                 :
                                  

                                 (
                                    <>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='adPrice' >Price</label>
                                            <input  id='adPrice' type="number" className='auth_input' value={price} onChange={(e) => setPrice(e.target.value)}  placeholder="00000" />
                                        </div>
                                    </>
                                 ) 

                                }
                                <div className={styles.form_control
}>
                                    <label htmlFor="location">Location</label>
                                    <Autocomplete  className={styles.form_control
} >
                                       <input type="text" name="location" id='location' value={location} placeholder='Location' onChange={(e:any) => {  setLocation(e.target.value)}}  onBlur={(e:any) => { setLocation(e.target.value)}} />
                                    </Autocomplete>
                                </div>

                                
                                <div className={styles.form_control
}>
                                    <label htmlFor="category">Currency</label>
                                    <select name="category" id='currency' value={currency} onChange={(e) => set_currency(e.target.value)}>
                                    {Currencies.map((element, index) => {
                                        return <option key={index} value={index}>{element}</option>
                                    })}
                                    </select>
                                </div>
                                <div className={styles.form_control
}>
                                    <label htmlFor="description">Description</label>
                                    <textarea name="description" id='description' value={desc} onChange={(e) => set_desc(e.target.value)} placeholder='Write down your description'>
                                    </textarea>
                                </div>
                    <div className={styles.form_control
}>
                        <p className='auth_lable'>Add Images</p>
                        <div style={{ display: 'flex', flexWrap:'wrap' }}>
                            <div>
                                <label htmlFor="add_image" className="img_upload_btn">
                                    <FiUpload />
                                    <span>Upload</span>
                                </label>
                                <input type="file" accept="image/*" id="add_image" className='auth_input' hidden onChange={(e:any) => upload_image(e.target.files[0])} />
                            </div>
                            {uploaded_images.map((element: any, index: any) => {
                                return <div key={index} className="upload_image-containers">
                                    <img src={element['image']['Location']} alt="uploaded" />
                                    <div className="uploaded_images_bot">
                                        <button onClick={()=>set_main_image(index)}>
                                            {index === main_image ? <AiFillCheckSquare color="#1B9CEA" /> : <AiOutlineCheckSquare color="#999"/>}
                                        </button>
                                        <button className="delete_image" onClick={()=>remove_image(index)}><AiFillDelete /></button>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <button className="btn-primary" onClick={changes}>Save Changes</button>
            </div>
        </div>
        
    )
}
