import React from 'react';
import { Link } from "react-router-dom";
import logo from  "../../assets/logo/4x/Asset 2.png"
export default function Logo() {
    return (
        <div className="logo">
        <Link to='/'>
            <img src={logo} alt="logo" />
        </Link> 
        </div>

    )
}
