import React from 'react';
import '../css/Profile.scss';
import Navigation from "../components/navigation/Navigation";
import AlertBox from "../components/general/AlertBox";
import { Outlet} from "react-router-dom";
import ProfileNav from '../components/profile/Navigation';
import { useLocation } from 'react-router-dom';
import Footer from '../components/general/Footer';


export default function Profile() {

  return (
    <>
      <Navigation />
      <AlertBox />
      <div className="profile-container">
        <div className="profile-wrapper">
          <div className="profile_navigation">
            <ProfileNav location={useLocation().pathname}/>
          </div>
          <div className="profile_module">
            <Outlet/>
          </div>
        </div>
      </div>
      <Footer/>

    </>
  );
}
