import React from 'react';
import { useEffect } from "react";
import { get_shop_products } from "../../services";
import axios from "axios";
import { useState } from "react";
import { toggle_loader } from '../../redux/actions/general';
import { useDispatch, useSelector} from "react-redux"
import { set_active_products } from "../../redux/actions/shop";
import Product from "../promo_cards/Product";

export default function ActiveProducts({shop_id}) {
    const dispatch = useDispatch()
    const active_products = useSelector(state => state.shops).active_products
    const [products, set_products] = useState(active_products)
    

    useEffect(()=>{
        if(products.length < 1) dispatch(toggle_loader(true))
        let config = get_shop_products(shop_id)
        axios(config).then(function (response) {
            let date = (new Date()).getTime()
            let new_products = response['data']['products'].filter(element => {
                let start = (new Date(element['start'])).getTime()
                let end = (new Date(element['end'])).getTime()
                return end > date && start < date
            })
            set_products(new_products)
            dispatch(set_active_products(new_products))
            dispatch(toggle_loader(false))
        })
        .catch(function (error) {
            console.log(error);
            dispatch(toggle_loader(false))
        });
    },[shop_id, dispatch, products.length])

    const allowed_actions = ["End", "Edit", "Delete", "Add to Watchlist"]

    return (
        <div className="shop_products">
            {products.map((product, index) => {
                return <Product product={product} allowed_actions={allowed_actions} key={index} />
            })}
        </div>
    )
}
