export default function Session(session, type) {
    let accessToken = localStorage.getItem('accessToken')
    let refreshToken = localStorage.getItem('refreshToken')
    
    if(type === "GET"){
        return {
            refreshToken,
            accessToken
        }
    }
    else if(type === "POST"){
        if(accessToken !== session.accessToken){
            localStorage.setItem("accessToken", session.accessToken)
            console.log("set",accessToken )
        }
        if(refreshToken !== session.refreshToken){
            localStorage.setItem("refreshToken", session.refreshToken)
            console.log("set",refreshToken )
        }
    }
    else if(type === "LOGOUT"){
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('accessToken')
    }
    return
}