export const GET_ALL_SHOPS =  "GET_ALL_SHOPS";
export const get_all_shops = (data) => {
    return { type: GET_ALL_SHOPS, payload:data}
};

export const SET_ALL_PRODUCTS =  "SET_ALL_PRODUCTS";
export const set_all_Products = (data) => {
    return { type: SET_ALL_PRODUCTS, payload:data}
};

export const SET_ACTIVE_PRODUCTS =  "SET_ACTIVE_PRODUCTS";
export const set_active_products = (data) => {
    return { type: SET_ACTIVE_PRODUCTS, payload:data}
};

export const SET_PAST_PRODUCTS =  "SET_PAST_PRODUCTS";
export const set_past_products = (data) => {
    return { type: SET_PAST_PRODUCTS, payload:data}
};

export const SET_WATCHLIST = "SET_WATCHLIST";
export const set_watchlist = (data) => {
    return { type: SET_WATCHLIST, payload:data}
};

export const SET_FAVOURITES = "SET_FAVOURITES";
export const set_favourites = (data) => {
    return { type: SET_FAVOURITES, payload:data}
};