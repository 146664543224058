import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { BiX } from "react-icons/bi";
import { useState } from 'react';
import styles from './CreateAdModal.module.scss';
import * as SERVICE from '../../services';
import { useSelector, useDispatch } from "react-redux";
import { modal_open, toggle_loader } from '../../redux/actions/general';
import Categories from "../../assets/Categories";
import Currencies from "../../assets/Currencies";
import httpClient from '../../services/ApiCallHandler';
import { FiUpload } from "react-icons/fi"
import { AiFillCheckSquare, AiFillDelete, AiOutlineCheckSquare } from "react-icons/ai"
import { POST_TYPES } from "../../services/Helpers";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from '@react-google-maps/api';

const CreateAdModal = (props:any) => {  
    const navigate = useNavigate();

    const dispatch = useDispatch()
    const profile = useSelector((state: any) => state.profile);
    const [product_name, set_product_name] = useState("")
    const [desc, set_desc] = useState("")
    const [myShops, setMyShops] = useState([]) as any;
    const [selectedShop, setSelectedShop] = useState("")
    const [postType, setPostType] = useState("advert")
    const [bidStartPrice,setBidStartPrice] = useState("0")
    const [old_price, set_old_price] = useState("")
    const [new_price, set_new_price] = useState("")
    const [category, set_category] = useState("0")
    const [currency, set_currency] = useState("USD")
    const [start, set_start] = useState("")
    const [end, set_end] = useState("")
    const [price,setPrice] = useState("0")
    const [ownerType, setOwnerType] = useState("private")
    const [location, setLocation] = useState("")

    const [promo_desc, set_promo_desc] = useState("")
    const [uploaded_images, set_uploaded_images] = useState([]) as any;
    const [main_image, set_main_image] = useState(-1)



    const [selected, setSelected] = useState("general");

    const create = () => {
        console.log(location)
        dispatch(toggle_loader(true))
        let start_data = (new Date(start? start : new Date())).getTime() as any;
        let end_data = (new Date(end ? end : new Date())).getTime() as any;

        let formData = new FormData()
        formData.append("name", product_name)
        formData.append("title", promo_desc)
        formData.append("description",  desc)
        formData.append("oldPrice", old_price)
        formData.append("newPrice", new_price)
        formData.append("price", price);  
        formData.append("currency", currency)
        formData.append("storeId",(ownerType === "private")? profile.id : selectedShop)
        formData.append("bidPrice", bidStartPrice)
        formData.append("userId", profile['id'])
        formData.append("type", postType)
        formData.append("category", category)
        formData.append("ownerType", ownerType)
        formData.append("location", location)
        formData.append("start", start_data)
        formData.append("end", end_data)
        uploaded_images.forEach((element: any) => {
            formData.append("images", element['file'])
        })
        const config = SERVICE.create_post(formData)
        httpClient(config).then(function (response) {
            dispatch(toggle_loader(false))
            if (response.statusText === "Created") {
                reset_values()
            } else {
                alert("not created")
            }
        })
            .catch(function (error) {
                dispatch(toggle_loader(false))
                console.log(error);
            });
    }
    const reset_values = () => {
        set_product_name("")
        set_desc("")
        set_old_price("")
        setBidStartPrice("0")
        set_new_price("")
        set_category("0")
        set_currency("USD")
        set_start("")
        set_end("")
        setPrice("0")
        setPostType("")
        set_promo_desc("")
        set_uploaded_images([])
    }

    const upload_image = (image: any) => {
        let obj = { file: image, src: "" } as any
        const reader = new FileReader();
        reader.onloadend = () => {
            obj['src'] = reader.result
            set_uploaded_images((uploaded_images: any) => [...uploaded_images, obj]) as any;
        }
        reader.readAsDataURL(image);
    }
    const remove_image = (position: any) => {
        let images: any[] = []
        uploaded_images.forEach((element: any, index: any) => {
            if (position !== index) images.push(element)
        });
        set_uploaded_images(images);
        set_uploaded_images(images);
    }
   
    
    useEffect(()=>{

      
        if(profile && profile.id){
            // if(shops.length < 1){
            //     dispatch(toggle_loader(true))
            // }
            let config = SERVICE.getUserShops(profile.id)
            httpClient(config).then(function (response) {
                let shops = response['data']['Shops'].reverse()             
                setMyShops(shops)
            })
            .catch(function (error) {
                console.log(error);
            });
        } else {
            if(props.modalState){
                dispatch(modal_open(false))
                navigate("/Login")
            }
        }
        
    },[dispatch, props.modalState, navigate, profile, profile.id])
    


    return (
        // <div className='post_modal' style={btnDisplay? ({ display: 'block' }) : ({ display: "none" })}>
            <Modal isOpen={props.modalState}  overlayClassName={styles.overlay} className={styles.modal}  id="modal" ariaHideApp={false}>
                <div className={styles.modal_top}>
                    <h3>
                        Create Advert
                    </h3>
                <span
                    className="close-modal"
                    onClick={() => {
                        props.setModalState(false);

                    }}
                >
                    <BiX />
                </span>
                </div>
                <div className={styles.modal_container}>
                <nav className={styles.nav}>
                    <span className={selected === "general" ? styles.nav_item_active : styles.nav_item} onClick={() => setSelected("general")}>General</span>
                    <span className={selected === "image" ? styles.nav_item_active : styles.nav_item} onClick={() => setSelected("image")}>Image</span>
                </nav>
                <div className={styles.form}>
                    {
                        selected === "general" ? (
                            <>
                                <div className={styles.form_control
}>
                                    <label htmlFor='auth_lable'>Post Type</label>
                                    <select id='auth_input' value={postType} onChange={(e) => setPostType(e.target.value)}>
                                        {POST_TYPES.map((element, index) => {
                                            return <option key={index} value={element.slug}>{element.value}</option>
                                        })}
                                    </select>
                                </div>

                                <div className={styles.form_control
}>
                                    <label htmlFor="product">Product Name</label>
                                    <input type="text" name="product" id='product' placeholder='Product Name' value={product_name} onChange={(e) => set_product_name(e.target.value)} />
                                </div>
                                <div className={styles.form_control
}>
                                    <label htmlFor="category">Category</label>
                                    <select name="category" id='category' value={category} onChange={(e) => set_category(e.target.value)}>
                                        {Categories.map((element, index) => {
                                            return <option key={index} value={element.code}>{element.name}</option>
                                        })}
                                    </select>
                                </div>

                                <div className={styles.form_control
}> 
                                <label htmlFor="postOwner">Post Owner</label>
                                  <div className={styles.check_radio} id="postOwner">
                                    <div className={styles.radio_wrapper} > 
                                       <label htmlFor="private">Private</label> 
                                       <input type="radio" id="private" checked={ownerType === "private"}  name="PostType" value="private"  onChange={(e) => setOwnerType(e.target.value)} />   

                                   </div>                          
                                   {myShops.length > 0 && (

                                    <div className={styles.radio_wrapper}> 
                                        <label htmlFor="firm">Firm</label>
                                        <input type="radio" id="firm" name="PostType" checked={ownerType === "firm"} disabled={myShops.length === 0} value="firm" onChange={(e) => setOwnerType(e.target.value)} />          
                                    </div>

                                   )}
                                    

                                  </div>     
                                </div>

                                {ownerType === "firm" && myShops.length > 0 &&  
                                    (
                                        <>
                                            <div className={styles.form_control
}>
                                                <label htmlFor="shop">Shop</label>
                                                <select name="shop" id='shop'  value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}>
                                                <option value="">choise from list</option>
                                                    {myShops.map((element:any, index:any) => {
                                                        return <option key={index} value={element._id}>{element.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </>
                                    )
                                }

                                    
                              { postType === "promotion"?
                                (  
                                    <>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='oldPrice' >Original Price</label>
                                            <input id='oldPrice' type="number" className='auth_input' value={old_price} onChange={(e) => set_old_price(e.target.value)} placeholder="00000"/>
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='newPrice' >Promo Price</label>
                                            <input  id='newPrice' type="number" className='auth_input' value={new_price} onChange={(e) => set_new_price(e.target.value)}  placeholder="00000" />
                                        </div>

                                        <div className={styles.form_control
}>
                                            <label  htmlFor='startDate'>Start Date</label>
                                            <input  id='startDate' type="datetime-local" className='auth_input' value={start} onChange={(e) => set_start(e.target.value)} />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='endate' >End Date</label>
                                            <input id="endate" type="datetime-local" className='auth_input' value={end} onChange={(e) => set_end(e.target.value)} />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='promotionDesc'>Promotion Description</label>
                                            <textarea  id='promotionDesc' placeholder="Why are you promoting this Product?" className='auth_input' rows={5} value={promo_desc} onChange={(e) => set_promo_desc(e.target.value)}></textarea>
                                        </div>
                                        </>
                                 ) :
                                 postType === "auction"? 

                                 (
                                    <>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='bitPrice' >Bid Start Price</label>
                                            <input  id='bitPrice' type="number" className='auth_input' value={bidStartPrice} onChange={(e) => setBidStartPrice(e.target.value)}  placeholder="00000" />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label  htmlFor='startDate'>Start Date</label>
                                            <input  id='startDate' type="datetime-local" className='auth_input' value={start} onChange={(e) => set_start(e.target.value)} />
                                        </div>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='endate' >End Date</label>
                                            <input id="endate" type="datetime-local" className='auth_input' value={end} onChange={(e) => set_end(e.target.value)} />
                                        </div>
                                    </>
                                 ) 
                                 :
                                  

                                 (
                                    <>
                                        <div className={styles.form_control
}>
                                            <label htmlFor='adPrice' >Price</label>
                                            <input  id='adPrice' type="number" className='auth_input' value={price} onChange={(e) => setPrice(e.target.value)}  placeholder="00000" />
                                        </div>
                                    </>
                                 ) 

                                }
                                <div className={styles.form_control
}>
                                    <label htmlFor="location">Location</label>
                                    <Autocomplete  className={styles.form_control
} >
                                       <input type="text" name="location" id='location' placeholder='Location' onChange={(e:any) => {  setLocation(e.target.value)}}  onBlur={(e:any) => { setLocation(e.target.value)}} />
                                    </Autocomplete>
                                </div>

                                
                                <div className={styles.form_control
}>
                                    <label htmlFor="category">Currency</label>
                                    <select name="category" id='currency' value={currency} onChange={(e) => set_currency(e.target.value)}>
                                    {Currencies.map((element, index) => {
                                        return <option key={index} value={index}>{element}</option>
                                    })}
                                    </select>
                                </div>
                                <div className={styles.form_control
}>
                                    <label htmlFor="description">Description</label>
                                    <textarea name="description" id='description' value={desc} onChange={(e) => set_desc(e.target.value)} placeholder='Write down your description'>
                                    </textarea>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.form_control
}>
                                <p className='auth_lable'>Add Images</p>
                                <div style={{ display: 'flex', flexWrap:'wrap' }}>
                                    <div>
                                        <label htmlFor="add_image" className="img_upload_btn">
                                            <FiUpload />
                                            <span>Upload</span>
                                        </label>
                                        <input type="file" accept="image/*" id="add_image" className='auth_input' hidden onChange={(e:any) => upload_image(e.target.files[0])} />
                                    </div>
                                    {uploaded_images.map((element:any, index:any) => {
                                        return <div key={index} className="upload_image-containers">
                                            <img src={element.src} alt="uploaded" />
                                            <div className="uploaded_images_bot">
                                                <button onClick={()=>set_main_image(index)}>
                                                    {index === main_image ? <AiFillCheckSquare color="#1B9CEA" /> : <AiOutlineCheckSquare color="#999"/>}
                                                </button>
                                                <button className="delete_image" onClick={()=>remove_image(index)}><AiFillDelete /></button>
                                            </div>
                                        </div>
                                    })}
                                </div>
                                </div>

                                <button className={styles.modal_btn} onClick={() => create()}>Done</button>
                            </>
                        )
                    }
                </div>
                </div>

            </Modal>
        // </div>
    )

}

export default CreateAdModal;