// import styles from './Auth.module.scss';
import { Link } from "react-router-dom";
import React from 'react';
import "./Auth.scss";
import { FcGoogle } from "react-icons/fc";
import { useState } from "react";
import axios from "axios";
import * as SERVICE from "../../services";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Session from '../../services/Session';
import httpClient from '../../services/ApiCallHandler';
import { useEffect } from 'react'
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { userDataObjectFormatter } from "../../services/Helpers";
import { toggle_loader } from "../../redux/actions/general";
import { load } from "../../redux/actions/profile";

const Auth = ({ Authform }: any) => {

  let navigate = useNavigate();
  const dispatch = useDispatch()
  const { search } = window.location;
  const social = new URLSearchParams(search).get('social');
  const BASE = process.env.REACT_APP_API_BASE_URL;
  const [firstName, set_firstName] = useState('')
  const [lastName, set_lastName] = useState('')
  const [email, set_email] = useState('')
  const [pass, set_pass] = useState('')
  const [cpass, set_cpass] = useState('')
  const [login_email, set_login_email] = useState('')
  const [login_pass, set_login_pass] = useState('')
  const location = useLocation().pathname;

  const register = () => {
    dispatch(toggle_loader(true));
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: pass,
      comfirmPassword: cpass,
    };
    let config = SERVICE.register_new_user(data);
    axios(config)
      .then(function (response) {

        let data = userDataObjectFormatter(response.data.user);
        dispatch(toggle_loader(false));
        dispatch(load(data));
        navigate("/");
      })
      .catch(function (error) {
        dispatch(toggle_loader(false));
        console.log(error);
      });
  };

  const login = () => {
    let data = {
      email: login_email,
      password: login_pass,
    }
    dispatch(toggle_loader(true))
    let config = SERVICE.login(data)
    httpClient(config).then(function (response) {
      let session = response.data[1].session
      Session(session, "POST")
      let data = userDataObjectFormatter(response.data[0]);  
      dispatch(toggle_loader(false))
      dispatch(load(data))
      navigate('/');
    })
      .catch(function (error) {
        dispatch(toggle_loader(false))
        console.log(error);
      });
  }
  const loginWithSocial = (e: any, type: any) => {
    e.preventDefault();
    if (type === "google") {
      window.open(`${BASE}/api/auth/google`, "_self");
    }
  }
  const validateSocialAuth = useCallback(() => {
    if (social) {
      SERVICE.socialLogin(social).then(function (response: any) {
        let session = { accessToken: response.data[0].accessToken, refreshToken: response.data[0].refreshToken }
        Session(session, "POST")
        const data = userDataObjectFormatter(response.data[0].userData[0]);
        dispatch(toggle_loader(false))
        dispatch(load(data))
        navigate('/');
      })
        .catch(function (error) {
          dispatch(toggle_loader(false))
          console.log(error);
        });
    }
  }, [social, navigate, dispatch])
  useEffect(() => {
    validateSocialAuth();
  }, [validateSocialAuth])


  const formValidate = (e: any) => {
    e.preventDefault();
    let event = e;
    if (e.target.id === "login") {

      if (login_email === "") {
        event.target.elements.email.style.border = '1px solid red'
      } if (login_pass === "") {
        event.target.elements.password.style.border = '1px solid red'
      } else if (login_email !== "" && login_pass !== "") {
        login()
      }

    } else {
      if (!firstName) {
        event.target.elements.firstname.style.border = '1px solid red'
      }
      register()
    }



  }
  return (
    <>
      <div className="auth">
        <div className="auth-image">
          <div className="image-container">
            <img src="https://images.unsplash.com/photo-1559752453-b9195afa100c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" alt="girl on phone" />
          </div>
        </div>
        <div className="auth-form">
          <Authform
            formValidate={formValidate}
            loginWithSocial={loginWithSocial}

            login_email={login_email}
            set_login_email={set_login_email}
            login_pass={login_pass}
            set_login_pass={set_login_pass}
            location={location}
            set_firstName={set_firstName}
            firstName={firstName}
            lastName={lastName}
            set_lastName={set_lastName}
            email={email}
            set_email={set_email}
            pass={pass}
            set_pass={set_pass}
            cpass={cpass}
            set_cpass={set_cpass}
            FcGoogle={FcGoogle}
            Link={Link}
          />
        </div>
      </div>
    </>
  )
}


export default Auth;

