import React from 'react';
import styles from './Category.module.scss';
import Categories from "../../assets/Categories";
import {Swiper, SwiperSlide} from 'swiper/react';
import { Link } from "react-router-dom";
import {Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation'
 
 const SimpleSlider = () => {

  return (
    <Swiper 
    slidesPerView={'auto'}
    navigation={true}
    modules={[Navigation]}
    className="category_swiper">
        
        {Categories && Categories.map((category, index)=>{
        return <SwiperSlide className={styles.category}  key={index}>
              <Link to={`search?category=${category.code}`}>{category.name}</Link>
              </SwiperSlide>
      })}
        
    </Swiper>
  );
}

const Category = ()=>{

    return(
        <nav className="navbar">
        <div className="container">
            <div className={styles.category_wrap}>
            <div className={styles.category_slider}>
            <SimpleSlider/>
            </div>
            </div>
     
        </div>
    </nav>
    )
}

export default Category;



